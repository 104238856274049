import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getPlayerDetailService,
  createPlayerService,
  getAllPlayersService,
  updatePlayerService,
  removePlayerMFAService,
  updatePlayerStatusService,
  getLoginLogs,
  getOnlinePlayers,
  addPlayerCommentDetailsService,
  getPlayerCommentDetailService,
  updatePlayerCommentStatusService,
  addPlayerGroupDetailsService,
  sendEmailToPlayersService,
  getEmailsDetailService,
  sendBonusAmountToPlayersService,
  getIndividualPlayerBonusDetailService
} from 'network/services/players.service'

export const fetchAllPlayers = createAsyncThunk(
  'fetch/allPlayers',
  async (data, thunkApi) => {
    try {
      const res = await getAllPlayersService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchPlayerDetails = createAsyncThunk(
  'fetch/playerDetail',
  async (userId, thunkApi) => {
    try {
      const res = await getPlayerDetailService(userId)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchPlayerCommentDetails = createAsyncThunk(
  'fetch/playerCommentsDetail',
  async (userId, thunkApi) => {
    try {
      const res = await getPlayerCommentDetailService(userId)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const addPlayerGroupDetails = createAsyncThunk(
  'add/playerGroups',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await addPlayerGroupDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const addPlayerCommentDetails = createAsyncThunk(
  'add/playerComments',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await addPlayerCommentDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updatePlayerCommentDetails = createAsyncThunk(
  'update/playerComments',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updatePlayerCommentStatusService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createPlayer = createAsyncThunk(
  'create/player',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createPlayerService(data)
      onSuccess(res.newUser.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updatePlayer = createAsyncThunk(
  'update/player',
  async ({ payload, onSuccess }, thunkApi) => {
    try {
      const res = await updatePlayerService(payload)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updatePlayerStatus = createAsyncThunk(
  'update/playerStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updatePlayerStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const removePlayerMFA = createAsyncThunk(
  'remove/playerMFA',
  async ({ userId, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await removePlayerMFAService(userId)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchLoginLogs = createAsyncThunk(
  'fetch/loginLogs',
  async (data, thunkApi) => {
    try {
      const res = await getLoginLogs(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchOnlinePlayers = createAsyncThunk(
  'fetch/onlinePlayers',
  async (data, thunkApi) => {
    try {
      const res = await getOnlinePlayers(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const sendEmailToPlayers = createAsyncThunk(
  'send/sendEmailToPlayers',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await sendEmailToPlayersService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchEmailDetails = createAsyncThunk(
  'fetch/emailDetails',
  async (userId, thunkApi) => {
    try {
      const res = await getEmailsDetailService(userId)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const sendBonusAmountToPlayers = createAsyncThunk(
  'send/sendEmailToPlayers',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await sendBonusAmountToPlayersService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchIndividualPlayerBonusDetails = createAsyncThunk(
  'fetch/individualPlayerBonusDetails',
  async (data, thunkApi) => {
    try {
      const res = await getIndividualPlayerBonusDetailService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
