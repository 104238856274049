import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

/**
 * Service to update crm details
 */
export const updateCrmDetailsService = (crmData) => {
  return axiosInstance(METHOD_TYPES.put, '/crm/update-crm', crmData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.crmDetailsUpdated
  })
}

/**
 * Service to get crm details
 */
export const getCrmDetailService = (crmId) => {
  return axiosInstance(METHOD_TYPES.get, '/crm/crm-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { crmId }
  })
}

/**
 * Service to create crm
 * @param {object} data - object contains all required properties to create new crm
 */
export const createCrmService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/crm/create-crm', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.crmCreatedSuccessfully
  })
}

/**
 * Service to fetch all crm
 */
export const getAllCrmService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/crm/all-crm', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to check mail preview by sending test mail
 */
export const checkMailPreviewService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/crm/check-crm-preview', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.previewEmailSendSuccessfully
  })
}

/**
 * Service to schedule mail to users
 */
export const scheduleMailService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/crm/schedule-mail', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.mailScheduledSuccessfully
  })
}
