import { createAsyncThunk } from '@reduxjs/toolkit'
import { createAdminService, createRoleService, updateAdminRoleService, getAllRolesService, deactivateTwoFactorService, forgotPasswordService, generateQrCodeService, getAdminDetailService, getAllAdminsService, resetPasswordService, updateAdminProfileDetailsService, updateAdminDetailsService, updateAdminStatusService, verifyTwoFactorTokenService, getAllPermissionsService, getRoleDetailService } from 'network/services/admins.service'
import { fetchUserInformation } from './user'

/**
 * Get All Admins Thunk
 */
export const fetchAllAdmins = createAsyncThunk(
  'fetch/allAdmins',
  async (data, thunkApi) => {
    try {
      const res = await getAllAdminsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAdminsDetails = createAsyncThunk(
  'fetch/adminDetail',
  async (adminId, thunkApi) => {
    try {
      const res = await getAdminDetailService(adminId)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Admin Thunk
 */
export const createAdmin = createAsyncThunk(
  'create/admin',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createAdminService(data)
      onSuccess(res.newAdmin.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Admin Thunk
 */
export const updateAdminProfile = createAsyncThunk(
  'update/adminDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateAdminProfileDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateAdmin = createAsyncThunk(
  'update/adminDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateAdminDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Change Admin Status Thunk
 */
export const updateAdminStatus = createAsyncThunk(
  'update/adminStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateAdminStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Admin Forgot Password Thunk
 */
export const forgotPassword = createAsyncThunk(
  'admin/forgot-password',
  async (email, thunkApi) => {
    try {
      const res = await forgotPasswordService(email)
      return res
    } catch (errors) {
      return thunkApi.rejectWithValue(errors[0].description)
    }
  }
)

/**
 * Admin Reset Password Thunk
 */
export const resetPassword = createAsyncThunk(
  'admin/reset-password',
  async ({ adminId, token, password, onSuccessRedirect }, thunkApi) => {
    try {
      const res = await resetPasswordService({ adminId, token, password })
      onSuccessRedirect()
      return res
    } catch (errors) {
      return thunkApi.rejectWithValue(errors[0].description)
    }
  }
)

/**
 * Admin Generate 2FA setup QR code Thunk
 */
export const generateQr = createAsyncThunk(
  'admin/generate-qr',
  async ({ openQrDialog }, thunkApi) => {
    try {
      const res = await generateQrCodeService()
      openQrDialog()
      return res
    } catch (errors) {
      return thunkApi.rejectWithValue(errors[0].description)
    }
  }
)

/**
 * Admin verify 2FA token Thunk
 */
export const verifyTwoFactorToken = createAsyncThunk(
  'admin/verify-2fa',
  async ({ otp, closeQrDialog }, thunkApi) => {
    try {
      const res = await verifyTwoFactorTokenService(otp)
      thunkApi.dispatch(fetchUserInformation())
      closeQrDialog()
      return res
    } catch (errors) {
      return thunkApi.rejectWithValue(errors[0].description)
    }
  }
)

/**
 * Admin deactivate 2FA Thunk
 */
export const deactivateMFA = createAsyncThunk(
  'admin/deactivate-2fa',
  async ({ adminId, closeDialog }, thunkApi) => {
    try {
      const res = await deactivateTwoFactorService(adminId)
      thunkApi.dispatch(fetchUserInformation())
      closeDialog()
      return res
    } catch (errors) {
      return thunkApi.rejectWithValue(errors[0].description)
    }
  }
)

/**
 * Create Role Thunk
 */
export const createRole = createAsyncThunk(
  'create/role',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createRoleService(data)
      onSuccess(res.newRole.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Get All Admins Thunk
 */
export const fetchAllRoles = createAsyncThunk(
  'fetch/allRoles',
  async (data, thunkApi) => {
    try {
      const res = await getAllRolesService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Role Thunk
 */
export const updateRole = createAsyncThunk(
  'update/adminRole',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateAdminRoleService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Get All Permissions Thunk
 */
export const fetchAllPermissions = createAsyncThunk(
  'fetch/allPermissions',
  async (data, thunkApi) => {
    try {
      const res = await getAllPermissionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchRoleDetails = createAsyncThunk(
  'fetch/roleDetails',
  async (id, thunkApi) => {
    try {
      const res = await getRoleDetailService(id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
