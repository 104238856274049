import { createSlice } from '@reduxjs/toolkit'
import { fetchAllRankingLevels } from 'redux-thunk/thunk/rankingLevels'

const initialState = {
  allRankLevels: null
}

const rankingLevelSlice = createSlice({
  name: 'rankingLevelSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRankingLevels.fulfilled, (state, action) => {
        return {
          ...state,
          allRankLevels: action.payload
        }
      })
  }
})

export default rankingLevelSlice.reducer
