import { createSlice } from '@reduxjs/toolkit'
import { fetchAllAnnouncements } from 'redux-thunk/thunk/announcement'

const initialState = {
  allAnnouncements: null
}

const announcementSlice = createSlice({
  name: 'announcementSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAnnouncements.fulfilled, (state, action) => {
        return {
          ...state,
          allAnnouncements: action.payload
        }
      })
  }
})

export default announcementSlice.reducer
