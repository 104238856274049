import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllReferralService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/get-referral-user', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllReferredUsersService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/get-refer-user', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllIndividualReferredUsersStatsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/get-referral-user-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllReferralBonusSettingsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/referral-bonus', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
