import { createSlice } from '@reduxjs/toolkit'
import { fetchAllCrm, fetchCrmDetails } from 'redux-thunk/thunk/crmTemplate'

const initialState = {
  allCrm: null,
  crmDetail: null
}

const crmSlice = createSlice({
  name: 'crmSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCrm.fulfilled, (state, action) => {
        return {
          ...state,
          allCrm: action.payload
        }
      })
      .addCase(fetchCrmDetails.fulfilled, (state, action) => {
        return {
          ...state,
          crmDetail: action.payload
        }
      })
  }
})

export default crmSlice.reducer
