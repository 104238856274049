import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

/**
 * Service to update banner details
 */
export const updateBannerDetailsService = (bannerData) => {
  return axiosInstance(METHOD_TYPES.put, '/banners/update-banner', bannerData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.bannerDetailsUpdatedSuccessfully
  })
}

/**
 * Service to get banner details
 */
export const getBannerDetailService = (bannerId) => {
  return axiosInstance(METHOD_TYPES.get, '/banners/banner-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { bannerId }
  })
}

/**
 * Service to create banner
 * @param {object} data - object contains all required properties to create new banner
 */
export const createBannerService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/banners/create-banner', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.bannerCreatedSuccessfully
  })
}

/**
 * Service to fetch all banner
 */
export const getAllBannersService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/banners/all-banners', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to delete banner
 */
export const deleteBannersService = (bannerId) => {
  return axiosInstance(METHOD_TYPES.delete, `/banners/${bannerId}/delete-banner`, {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}
