import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Divider, TextField, InputAdornment, IconButton } from '@mui/material' // Typography
import PropTypes from 'prop-types'
// import { fetchAllConfigs } from 'redux-thunk/thunk/siteConfig'
import { handleDrawerToggle } from 'redux-thunk/redux/hamSlice'
import { DrawerHeader, StyledDrawer } from './styles'
import { ReactComponent as NotificationIcon } from 'assets/images/notification.svg'
import { ReactComponent as SidebarToggleIcon } from 'assets/images/sidebar-toggle.svg'
import { ReactComponent as RedirectIcon } from 'assets/images/redirect.svg'
import { ReactComponent as LogoIcon } from 'assets/images/logo-icon.svg'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import SearchIcon from '@mui/icons-material/Search'
import { ROUTE_PATHS } from 'utils/constants/index'
import { useNavigate } from 'react-router-dom'

const AppDrawer = ({ children, openDrawer, handleDrawer, text }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const { siteConfigs } = useSelector((state) => state?.siteConfiguration)
  const { adminUserDetails } = useSelector(state => state.auth)

  const [searchQuery, setSearchQuery] = useState('')

  // useEffect(() => {
  //   dispatch(fetchAllConfigs())
  // }, [])

  const filterMenuItems = (menuItems, query) => {
    const queries = query
      .toLowerCase()
      .split(' ')
      .filter((q) => q.trim() !== '')

    return menuItems.filter((menuItem) => {
      const label = menuItem.label.toLowerCase()
      return queries.every((q) => label.includes(q))
    })
  }

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const filteredChildren = React.Children.toArray(children).map((child) => {
    if (child.props.children.props.list) {
      const filteredList = filterMenuItems(
        child.props.children.props.list,
        searchQuery
      )
      return React.cloneElement(child, {
        children: React.cloneElement(child.props.children, {
          list: filteredList
        })
      })
    }
    return child
  })

  return (
    <StyledDrawer
      anchor='left'
      variant='persistent'
      open={openDrawer}
      className={!openDrawer ? 'sidebar-close' : ''}
    >
      <Box className='sidebar-wrap'>
        <Box>
          <DrawerHeader className='sidebar-header'>
            <img
              style={{ width: '150px', maxHeight: '162px' }}
              src='/images/brand_icon.svg'
              alt='logo'
              className='text-logo'
            />

            <Box className='icon-logo'>
              <LogoIcon />
              <SidebarToggleIcon
                className='toggle-icon'
                onClick={() => {
                  window.localStorage.setItem('darkMode', 'false')
                  dispatch(handleDrawerToggle())
                }}
              />
            </Box>
            <Box className='header-icons'>
              <NotificationIcon />
              <SidebarToggleIcon
                onClick={() => {
                  window.localStorage.setItem('darkMode', 'false')
                  dispatch(handleDrawerToggle())
                }}
              />
            </Box>
          </DrawerHeader>
        </Box>
        <Box className='search-wrap'>
          {/* <TextField
            id='outlined-basic'
            variant='outlined'
            placeholder='Search'
            value={searchQuery}
            onChange={handleSearchQueryChange}
          /> */}
          <TextField
            id='outlined-basic'
            variant='outlined'
            placeholder='Search'
            value={searchQuery}
            onChange={handleSearchQueryChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Divider
          sx={{
            backgroundImage:
              'linear-gradient(90deg,transparent,rgba(0,0,0,.5),transparent)'
          }}
          light
        />
        <Box sx={{ overflow: 'auto' }}>{filteredChildren}</Box>
        <Box className='userprofile-wrap'>
          <Box onClick={() => navigate(ROUTE_PATHS.settings)}>
            {adminUserDetails?.profileImageUrl
              ? <img src={adminUserDetails?.profileImageUrl} alt='profile Image' className='userprofile-img' />
              : <AccountCircleRoundedIcon className='userprofile-img' />}
          </Box>
          {/* <Box className='userprofile-text'>
            <Typography variant='h3'>Jacob Wojciechowski</Typography>
            <Typography variant='body1'>hello@jootbox.eu</Typography>
          </Box> */}
          <RedirectIcon className='direct-icon' onClick={() => navigate(ROUTE_PATHS.settings)} />
        </Box>
      </Box>
    </StyledDrawer>
  )
}

export default AppDrawer

AppDrawer.defaultProps = {
  openDrawer: true,
  handleDrawer: () => {},
  text: 'Default'
}

AppDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  handleDrawer: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired
}
