export const SuccessMessage = {
  login: 'loggedInSuccess',
  logout: 'loggedOutSuccess',
  changePassword: 'passwordUpdateSuccess',
  playerStatusUpdate: 'updatePlayerStatusSuccess',
  createOperator: 'operatorUserCreatedSuccess',
  resetPassword: 'resetPasswordSuccess',
  resetPasswordSuccessfully: 'resetPasswordSuccessfully',
  resetPasswordLinkGenerated: 'resetPasswordLinkGeneratedSuccessfully',
  gameSettingUpdated: 'updateGameSettingSuccess',
  adminDetailsUpdated: 'adminDetailsUpdated',
  twoFactorEnabledSuccessfully: 'twoFactorEnabledSuccessfully',
  twoFactorDisabledSuccessfully: 'twoFactorDisabledSuccessfully',
  gameStatusUpdatedSuccessfully: 'gameStatusUpdatedSuccessfully',
  providerStatusUpdatedSuccessfully: 'providerStatusUpdatedSuccessfully',
  imageUploadedSuccessfully: 'imageUploadedSuccessfully',
  statusUpdatedSuccessfully: 'Status updated successfully',
  categoryCreatedSuccessfully: 'categoryCreatedSuccessfully',
  categoryUpdatedSuccessfully: 'categoryUpdatedSuccessfully',
  providersDataUpdateSuccessfully: 'providersDataUpdateSuccessfully',
  chatRulesUpdatedSuccessfully: 'chatRulesUpdatedSuccessfully',
  adminStatusUpdatedSuccessfully: 'adminStatusUpdatedSuccessfully',
  adminCreatedSuccessfully: 'adminCreatedSuccessfully',
  adminRoleCreatedSuccessfully: 'adminRoleCreatedSuccessfully',
  affiliatePlayerStatusUpdatedSuccessfully: 'affiliatePlayerStatusUpdatedSuccessfully',
  affiliateCommissionSettingsDetailCreatedSuccessfully: 'affiliateCommissionSettingsDetailCreatedSuccessfully',
  affiliateCommissionSettledSuccessfully: 'affiliateCommissionSettledSuccessfully',
  affiliateCommissionSettingsDetailUpdatedSuccessfully: 'affiliateCommissionSettingsDetailUpdatedSuccessfully',
  announcementDetailsUpdatedSuccessfully: 'announcementDetailsUpdatedSuccessfully',
  announcementCreatedSuccessfully: 'announcementCreatedSuccessfully',
  bannerDetailsUpdatedSuccessfully: 'bannerDetailsUpdatedSuccessfully',
  bannerCreatedSuccessfully: 'bannerCreatedSuccessfully',
  referralBonusUpdatedSuccessfully: 'referralBonusUpdatedSuccessfully',
  referralBonusStatusUpdatedSuccessfully: 'referralBonusStatusUpdatedSuccessfully',
  joiningBonusUpdatedSuccessfully: 'joiningBonusUpdatedSuccessfully',
  joiningBonusStatusUpdatedSuccessfully: 'joiningBonusStatusUpdatedSuccessfully',
  bonusCreatedSuccessfully: 'bonusCreatedSuccessfully',
  depositBonusUpdatedSuccessfully: 'depositBonusUpdatedSuccessfully',
  otherBonusUpdatedSuccessfully: 'otherBonusUpdatedSuccessfully',
  freeSpinBonusUpdatedSuccessfully: 'freeSpinBonusUpdatedSuccessfully',
  bonusStatusUpdatedSuccessfully: 'bonusStatusUpdatedSuccessfully',
  bonusDetailsUpdatedSuccessfully: 'bonusDetailsUpdatedSuccessfully',
  languageStatusUpdatedSuccessfully: 'languageStatusUpdatedSuccessfully',
  chatLanguageCreatedSuccessfully: 'chatLanguageCreatedSuccessfully',
  chatGroupStatusUpdatedSuccessfully: 'chatGroupStatusUpdatedSuccessfully',
  chatGroupDetailsUpdatedSuccessfully: 'chatGroupDetailsUpdatedSuccessfully',
  chatGroupCreatedSuccessfully: 'chatGroupCreatedSuccessfully',
  cmsCreatedSuccessfully: 'cmsCreatedSuccessfully',
  cmsStatusUpdatedSuccessfully: 'cmsStatusUpdatedSuccessfully',
  crmCreatedSuccessfully: 'crmCreatedSuccessfully',
  previewEmailSendSuccessfully: 'previewEmailSendSuccessfully',
  mailScheduledSuccessfully: 'mailScheduledSuccessfully',
  cryptoInstrumentUpdatedSuccessfully: 'cryptoInstrumentUpdatedSuccessfully',
  cryptoInstrumentStatusUpdatedSuccessfully: 'cryptoInstrumentStatusUpdatedSuccessfully',
  gameHeroCreatedSuccessfully: 'gameHeroCreatedSuccessfully',
  gameHeroUpdatedSuccessfully: 'gameHeroUpdatedSuccessfully',
  gameHeroStatusUpdatedSuccessfully: 'gameHeroStatusUpdatedSuccessfully',
  gameBackgroundUpdatedSuccessfully: 'gameBackgroundUpdatedSuccessfully',
  resetDefaultThemeSuccessfully: 'resetDefaultThemeSuccessfully',
  playerGroupAddedSuccessfully: 'playerGroupAddedSuccessfully',
  playerCommentsAddedSuccessfully: 'playerCommentsAddedSuccessfully',
  playerCommentsStatusUpdatedSuccessfully: 'playerCommentsStatusUpdatedSuccessfully',
  playerCreatedSuccessfully: 'playerCreatedSuccessfully',
  playerDetailsUpdatedSuccessfully: 'playerDetailsUpdatedSuccessfully',
  playerMFARemovedSuccessfully: 'playerMFARemovedSuccessfully',
  emailSentSuccessfully: 'emailSentSuccessfully',
  bonusSentSuccessfully: 'bonusSentSuccessfully',
  promotionDetailsUpdatedSuccessfully: 'promotionDetailsUpdatedSuccessfully',
  promotionStatusUpdatedSuccessfully: 'promotionStatusUpdatedSuccessfully',
  promotionCreatedSuccessfully: 'promotionCreatedSuccessfully',
  rankingDetailsUpdatedSuccessfully: 'rankingDetailsUpdatedSuccessfully',
  rankingStatusUpdatedSuccessfully: 'rankingStatusUpdatedSuccessfully',
  rankingLevelCreatedSuccessfully: 'rankingLevelCreatedSuccessfully',
  reportedUserBlockedSuccessfully: 'reportedUserBlockedSuccessfully',
  reportedUserUnBlockedSuccessfully: 'reportedUserUnBlockedSuccessfully',
  sponsorStatusUpdatedSuccessfully: 'sponsorStatusUpdatedSuccessfully',
  sponsorCreatedSuccessfully: 'sponsorCreatedSuccessfully',
  themeUpdatedSuccessfully: 'themeUpdatedSuccessfully',
  themeStatusUpdatedSuccessfully: 'themeStatusUpdatedSuccessfully',
  themeCreatedSuccessfully: 'themeCreatedSuccessfully',
  upliftingWordDetailsUpdatedSuccessfully: 'upliftingWordDetailsUpdatedSuccessfully',
  upliftingWordStatusUpdatedSuccessfully: 'upliftingWordStatusUpdatedSuccessfully',
  manualAmountDepositedSuccessfully: 'manualAmountDepositedSuccessfully',
  wordCreatedSuccessfully: 'wordCreatedSuccessfully',
  orderChangedSuccessfully: 'orderChangedSuccessfully'
}

export const ErrorMessage = {
  internalServerError: 'internalServerError',
  unAuthorized: 'unAuthorized'
}

export const errorCodeToMessage = new Map([
  [600, 'internalServerError'],
  [601, 'loginFailedError'],
  [602, 'invalidCredentialsError'],
  [603, 'invalidTokenError'],
  [604, 'invalidSessionError'],
  [605, 'invalidAccessError'],
  [606, 'nonOperationalError'],
  [2001, 'createUserError'],
  [2004, 'dataMismatchError'],
  [2005, 'userNotFoundError'],
  [2006, 'duplicateEmailError']
])
