import { createSlice } from '@reduxjs/toolkit'
import { fetchAllIndividualReferredUsersStats, fetchAllReferrals, fetchAllReferredUsers, fetchReferralBonusSettings } from 'redux-thunk/thunk/referral'

const initialState = {
  allReferrals: null,
  allReferredUsers: null,
  allReferralBonusSettings: null,
  allIndividualReferredUserStats: null
}

const referralSlice = createSlice({
  name: 'referralSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllReferrals.fulfilled, (state, action) => {
        return {
          ...state,
          allReferrals: action.payload
        }
      })
      .addCase(fetchAllReferredUsers.fulfilled, (state, action) => {
        return {
          ...state,
          allReferredUsers: action.payload
        }
      })
      .addCase(fetchReferralBonusSettings.fulfilled, (state, action) => {
        return {
          ...state,
          allReferralBonusSettings: action.payload
        }
      })
      .addCase(fetchAllIndividualReferredUsersStats.fulfilled, (state, action) => {
        return {
          ...state,
          allIndividualReferredUserStats: action.payload
        }
      })
  }
})

export default referralSlice.reducer
