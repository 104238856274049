import { createSlice } from '@reduxjs/toolkit'
import { fetchAllPromotions } from 'redux-thunk/thunk/promotion'

const initialState = {
  allPromotions: null
}

const promotionSlice = createSlice({
  name: 'promotionSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPromotions.fulfilled, (state, action) => {
        return {
          ...state,
          allPromotions: action.payload
        }
      })
  }
})

export default promotionSlice.reducer
