import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

/**
 * Service to create chat rule
 */
export const createChatRuleService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/admins/create-chat-rules', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.chatRulesUpdatedSuccessfully
  })
}

/**
 * Service to get chat rules
 */
export const getChatRulesService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/admins/get-chat-rules', {}, {
    server: microServices.USER_URL
  })
}

/**
 * Service to get chat-language room
 */
export const getChatLanguageRoomService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user-chat/get-language-room', {}, {
    server: microServices.USER_URL,
    params: data
  })
}

/**
 * Service to update chat-language-room status
 */
export const updateChatLanguageRoomStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/user-chat/update-language-room', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.languageStatusUpdatedSuccessfully
  })
}

/**
 * Service to create chat-language-room
 * @param {object} data - object contains all required properties to create new chat-language-room
 */
export const createChatLanguageRoomService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user-chat/create-language-room', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.chatLanguageCreatedSuccessfully
  })
}

/**
 * Service to get all user chats
 */
export const getUserChatsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user-chat/get', {}, {
    server: microServices.USER_URL,
    params: data
  })
}

/**
 * Service to get all user chats tip
 */
export const getUserChatTippingService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user-chat/tip-transaction', {}, {
    server: microServices.USER_URL,
    params: data
  })
}

/**
 * Service to get all groups chats
 */
export const getChatGroupsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user-chat/get-chat-group', {}, {
    server: microServices.USER_URL,
    params: data
  })
}

/**
 * Service to update chat-groups status
 */
export const updateChatGroupsStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/user-chat/update-group-status', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.chatGroupStatusUpdatedSuccessfully
  })
}

/**
 * Service to edit chat-groups
 */
export const editChatGroupsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/user-chat/update-chat-group', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.chatGroupDetailsUpdatedSuccessfully
  })
}

/**
 * Service to create chat-groups
 * @param {object} data - object contains all required properties to create new chat-language-room
 */
export const createChatGroupsService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user-chat/create-chat-group', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.chatGroupCreatedSuccessfully
  })
}
