import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

/**
 * Service to update referral-bonus details
 */
export const updateReferralBonusDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/bonus/update-referral-bonus', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.referralBonusUpdatedSuccessfully
  })
}

/**
 * Service to get referral-bonus details
 */
export const getReferralBonusDetailService = (referralBonusId) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/referral-bonus-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { referralBonusId }
  })
}

/**
 * Service to fetch all referral-bonus
 */
export const getAllReferralBonusService = () => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/list-referral-bonuses', {}, {
    server: microServices.USER_URL,
    loader: null
  })
}

/**
 * Service to update referral-bonus status
 */
export const updateReferralBonusStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/bonus/update-referral-bonus-status', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.referralBonusStatusUpdatedSuccessfully
  })
}

/**
 * Service to update joining-bonus details
 */
export const updateJoiningBonusDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/bonus/update-joining-bonus', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.joiningBonusUpdatedSuccessfully
  })
}

/**
 * Service to get joining-bonus details
 */
export const getJoiningBonusDetailService = (joiningBonusId) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/joining-bonus-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { joiningBonusId }
  })
}

/**
 * Service to fetch all joining-bonus
 */
export const getAllJoiningBonusService = () => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/list-joining-bonus', {}, {
    server: microServices.USER_URL,
    loader: null
  })
}

/**
 * Service to update joining-bonus status
 */
export const updateJoiningBonusStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/bonus/update-joining-bonus-status', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.joiningBonusStatusUpdatedSuccessfully
  })
}

/**
 * Service to get information of deposit bonus
 */
export const getDepositBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/deposit-bonus', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to get information of freeSpin bonus
 */
export const getFreeSpinBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/freespins-bonus', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to create deposit bonus bonus
 */
export const createDepositBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/bonus/create-deposit-bonus', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.bonusCreatedSuccessfully
  })
}

/**
 * Service to create other bonus
 */
export const createOtherBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/bonus/create-other-bonus', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.bonusCreatedSuccessfully
  })
}

/**
 * Service to create freespins bonus bonus
 */
export const createFreeSpinBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/bonus/create-freespins-bonus', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.bonusCreatedSuccessfully
  })
}

/**
 * Service to get information of other-bonus
 */
export const getOtherBonusDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/bonus-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to update deposit-bonus details
 */
export const updateDepositBonusDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/bonus/update-deposit-bonus', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.depositBonusUpdatedSuccessfully
  })
}

/**
 * Service to other-bonus details
 */
export const updateOtherBonusDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/bonus/update-other-bonus', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.otherBonusUpdatedSuccessfully
  })
}

/**
 * Service to update free-spin-bonus details
 */
export const updateFreeSpinBonusDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/bonus/update-freespins-bonus', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.freeSpinBonusUpdatedSuccessfully
  })
}

/**
 * Service to status update deposit-bonus
 */
export const updateDepositBonusStatusService = (bonusData) => {
  return axiosInstance(METHOD_TYPES.put, '/bonus/update-deposit-bonus-status', bonusData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.bonusStatusUpdatedSuccessfully
  })
}

/**
 * Service to status update other-bonus
 */
export const updateOtherBonusStatusService = (bonusData) => {
  return axiosInstance(METHOD_TYPES.put, '/bonus/update-other-bonus-status', bonusData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.bonusStatusUpdatedSuccessfully
  })
}

/**
 * Service to status update freeSpin-bonus
 */
export const updateFreeSpinBonusStatusService = (bonusData) => {
  return axiosInstance(METHOD_TYPES.put, '/bonus/update-freespins-bonus-status', bonusData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.bonusStatusUpdatedSuccessfully
  })
}

/**
 * Service to get information of daily bonus
 */
export const getAllRewardBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/all-rewards', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to update reward details
 */
export const updateBonusDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/bonus/update-rewards', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.bonusDetailsUpdatedSuccessfully
  })
}

/**
 * Service to status update rewards
 */
export const updateRewardsStatusService = (bonusData) => {
  return axiosInstance(METHOD_TYPES.put, '/bonus/update-bonus-status', bonusData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.bonusStatusUpdatedSuccessfully
  })
}
