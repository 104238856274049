import { createSlice } from '@reduxjs/toolkit'
import { fetchAllRewards } from 'redux-thunk/thunk/bonus'
import { fetchAllPlayers, fetchPlayerDetails, fetchLoginLogs, fetchOnlinePlayers, fetchPlayerCommentDetails, fetchEmailDetails, fetchIndividualPlayerBonusDetails } from 'redux-thunk/thunk/players'

const initialState = {
  allPlayers: null,
  playerDetail: null,
  loginLogs: [],
  onlinePlayers: [],
  onlineUsersCount: 0,
  loginLogsCount: 0,
  playerComments: null,
  playerEmails: null,
  playerBonuses: null,
  individualPlayerBonuses: null
}

const playerSlice = createSlice({
  name: 'playerSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPlayers.fulfilled, (state, action) => {
        return {
          ...state,
          allPlayers: action.payload
        }
      })
      .addCase(fetchPlayerDetails.fulfilled, (state, action) => {
        return {
          ...state,
          playerDetail: action.payload
        }
      })
      .addCase(fetchLoginLogs.fulfilled, (state, action) => {
        return {
          ...state,
          loginLogs: action.payload.rows,
          loginLogsCount: action.payload.count
        }
      })
      .addCase(fetchOnlinePlayers.fulfilled, (state, action) => {
        return {
          ...state,
          onlinePlayers: action.payload.rows,
          onlineUsersCount: action.payload.count
        }
      })
      .addCase(fetchPlayerCommentDetails.fulfilled, (state, action) => {
        return {
          ...state,
          playerComments: action.payload
        }
      })
      .addCase(fetchEmailDetails.fulfilled, (state, action) => {
        return {
          ...state,
          playerEmails: action.payload
        }
      })
      .addCase(fetchAllRewards.fulfilled, (state, action) => {
        return {
          ...state,
          playerBonuses: action.payload
        }
      })
      .addCase(fetchIndividualPlayerBonusDetails.fulfilled, (state, action) => {
        return {
          ...state,
          individualPlayerBonuses: action.payload
        }
      })
  }
})

export default playerSlice.reducer
