import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

/**
 * Service to fetch all uplifting word
 */
export const getAllUpliftingWordsService = () => {
  return axiosInstance(METHOD_TYPES.get, '/uplifting-word/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

/**
 * Service to update uplifting word detail
 */
export const updateUpliftingWordDetailService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/uplifting-word/update', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.upliftingWordDetailsUpdatedSuccessfully
  })
}

/**
 * Service to update uplifting word status
 */
export const updateUpliftingWordStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/uplifting-word/update-status', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.upliftingWordStatusUpdatedSuccessfully
  })
}
