import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAllJoiningBonusService,
  getJoiningBonusDetailService,
  updateJoiningBonusDetailsService,
  updateJoiningBonusStatusService,
  getAllReferralBonusService,
  getReferralBonusDetailService,
  updateReferralBonusDetailsService,
  updateReferralBonusStatusService,
  getDepositBonusService,
  getFreeSpinBonusService,
  createDepositBonusService,
  updateDepositBonusDetailsService,
  updateFreeSpinBonusDetailsService,
  updateDepositBonusStatusService,
  updateFreeSpinBonusStatusService,
  createFreeSpinBonusService,
  updateRewardsStatusService,
  getAllRewardBonusService,
  updateBonusDetailsService,
  updateOtherBonusStatusService,
  updateOtherBonusDetailsService,
  getOtherBonusDetailsService,
  createOtherBonusService
} from 'network/services/bonus.service'

/**
 * Get All JoiningBonus Thunk
 */
export const fetchAllJoiningBonus = createAsyncThunk(
  'fetch/allJoiningBonus',
  async (data, thunkApi) => {
    try {
      const res = await getAllJoiningBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchJoiningBonusDetails = createAsyncThunk(
  'fetch/joiningBonusDetail',
  async (joiningBonusId, thunkApi) => {
    try {
      const res = await getJoiningBonusDetailService(joiningBonusId)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update JoiningBonus Thunk
 */
export const updateJoiningBonus = createAsyncThunk(
  'update/joiningBonusDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateJoiningBonusDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update JoiningBonus Status Thunk
 */
export const updateJoiningBonusStatus = createAsyncThunk(
  'update/joiningBonusStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateJoiningBonusStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Get All ReferralBonus Thunk
 */
export const fetchAllReferralBonus = createAsyncThunk(
  'fetch/allReferralBonus',
  async (data, thunkApi) => {
    try {
      const res = await getAllReferralBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchReferralBonusDetails = createAsyncThunk(
  'fetch/referralBonusDetail',
  async (referralBonusId, thunkApi) => {
    try {
      const res = await getReferralBonusDetailService(referralBonusId)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update ReferralBonus Thunk
 */
export const updateReferralBonus = createAsyncThunk(
  'update/referralBonusDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateReferralBonusDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update ReferralBonus Status Thunk
 */
export const updateReferralBonusStatus = createAsyncThunk(
  'update/referralBonusStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateReferralBonusStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Get All DepositBonus Thunk
 */
export const fetchAllDepositBonus = createAsyncThunk(
  'fetch/allDepositBonus',
  async (data, thunkApi) => {
    try {
      const res = await getDepositBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Get All FreeSpinBonus Thunk
 */
export const fetchAllFreeSpinBonus = createAsyncThunk(
  'fetch/allFreeSpinBonus',
  async (data, thunkApi) => {
    try {
      const res = await getFreeSpinBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create deposit bonus Thunk
 */
export const createDepositBonus = createAsyncThunk(
  'create/deposit-bonus',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createDepositBonusService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create other bonus Thunk
 */
export const createOtherBonus = createAsyncThunk(
  'create/other-bonus',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createOtherBonusService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create freespin bonus Thunk
 */
export const createFreeSpinBonus = createAsyncThunk(
  'create/freeSpin-bonus',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createFreeSpinBonusService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch bonus details Thunk
 */
export const getOtherBonusDetails = createAsyncThunk(
  'details/other-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getOtherBonusDetailsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update DepositBonus Thunk
 */
export const updateDepositBonus = createAsyncThunk(
  'update/depositBonusDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateDepositBonusDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update OtherBonus Thunk
 */
export const updateOtherBonus = createAsyncThunk(
  'update/otherBonusDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateOtherBonusDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update FreeSpinBonus Thunk
 */
export const updateFreeSpinBonus = createAsyncThunk(
  'update/freeSpinDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateFreeSpinBonusDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update deposit bonus status Thunk
 */
export const updateDepositBonusStatus = createAsyncThunk(
  'update/depositBonusStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateDepositBonusStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update other bonus status Thunk
 */
export const updateOtherBonusStatus = createAsyncThunk(
  'update/otherBonusStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateOtherBonusStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update free-spin bonus status Thunk
 */
export const updateFreeSpinBonusStatus = createAsyncThunk(
  'update/FreeSpinBonusStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateFreeSpinBonusStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Get All Reward bonus Thunk
 */
export const fetchAllRewards = createAsyncThunk(
  'fetch/allDailyBonus',
  async (data, thunkApi) => {
    try {
      const res = await getAllRewardBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update DailyBonus Thunk
 */
export const updateBonus = createAsyncThunk(
  'update/bonusDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateBonusDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update all-rewards status Thunk
 */
export const updateRewardsStatus = createAsyncThunk(
  'update/rewardsStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateRewardsStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
