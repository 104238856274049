import { createSlice } from '@reduxjs/toolkit'
import { fetchAllWords } from 'redux-thunk/thunk/words'
const initialState = {
  allWords: null
}

const wordsSlice = createSlice({
  name: 'wordsSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllWords.fulfilled, (state, action) => {
        return {
          ...state,
          allWords: action.payload
        }
      })
  }
})

export default wordsSlice.reducer
