import { createSlice } from '@reduxjs/toolkit'
import { fetchAllCms, fetchCmsDetails } from 'redux-thunk/thunk/cms'

const initialState = {
  allCms: null,
  cmsDetail: null
}

const cmsSlice = createSlice({
  name: 'cmsSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCms.fulfilled, (state, action) => {
        return {
          ...state,
          allCms: action.payload
        }
      })
      .addCase(fetchCmsDetails.fulfilled, (state, action) => {
        return {
          ...state,
          cmsDetail: action.payload.cms
        }
      })
  }
})

export default cmsSlice.reducer
