export const darkThemeColors = {
  greyShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  white: '#162331',
  sidebarBg: '#162331',
  main: '#0F1923',
  neonBlue: 'rgba(199, 240, 214, 0.5)',
  darkYellow: '#F59E0B',
  pink: '#EF4444',
  red: '#FF0000',
  darkRed: '#ff4523',
  darkRedHover: '#7e0101',
  forestGreen: '#308ed1',
  activeGreen: '#4BC500',
  shamrockGreen: '#308ed1',
  shamrockGreenHover: '#308ed1',
  blue: '#3B82F6',
  lightPurple: '#728CF8',
  purple: '#308ed1',
  purpleHover: '#1a147c',
  // grey300: '#e8e8e8',
  grey300: '#0F1923',
  grey400: '#CECECE',
  grey500: '#888',
  gunmetalBlue: 'rgba(225, 225, 225, 1)',
  darkNavyBlue: '#0F172A',
  black: 'rgba(225, 225, 225, 1)',
  textBlack: '#000',
  navyBlue: '#308ed1',
  neon: '#28c0c8',
  // new revamp
  drawerBg: 'linear-gradient(45deg,var(--cui-dark-start,#3c4b64) 0%,var(--cui-dark-stop,#212333 100%))',
  sidebarIconShadow: 'rgb(2 11 43 / 61%)',
  SidebarBrandBg: 'rgba(0, 0, 21, 0.15)',
  whiteColor_6: 'rgb(22 35 49 / 60%)',
  mainBg: '#162331',
  darkGrey: 'rgba(225, 225, 225, 1)',
  bgDarkGrey: '#308ed1',
  grey600: '#308ed1',
  greyText600: 'rgba(225, 225, 225, 1)',
  borderLight: '#0F1923',
  borderLightHead: '#29425b',
  lightShadow: '#0a060c',
  shadow1: 'rgb(0 0 0 / 67%)',
  cardHeadignText: 'rgba(225, 225, 225, 1)',
  primeBlue: '#308ed1',
  formInputBorder: '#6a7fa1',
  tableHeadText: '#8392ab',
  closeIconBg: '#b7b7b7',
  border1: '#0F1923',
  border2: 'rgba(79, 70, 229, 0.5)',
  border3: 'rgba(79, 70, 229, 0.6)',
  border4: '#e7eaec',
  btnBg1: '#308ed1',
  noteText: '#6e6d6d',
  graphGrid: 'rgba(219, 219, 235, 0.2)',
  textColor1: '#ffffff',
  textColor2: '#61DAFB',
  textColor3: '#4CAF50',
  headerShadow: 'rgb(22 35 49 / 60%)',
  black_05: 'rgba(0,0,0,.05)',
  black_87: 'rgba(225, 225, 225, 1)',
  textWhite: 'rgba(225, 225, 225, 1)',
  secondary: '#506b89',
  tableHeaderBg: '#182b3d',
  invertToggle: 'invert(0)',
  btnBlue: '#00A4FA',
  textgrey: '#8A90A2'

}
// light theme start
export const lightThemeColors = {
  greyShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  white: '#fff',
  sidebarBg: '#174566',
  main: '#f2f8fc',
  neonBlue: 'rgba(199, 240, 214, 0.5)',
  darkYellow: '#F59E0B',
  pink: '#EF4444',
  red: '#FF0000',
  darkRed: '#B40000',
  darkRedHover: '#7e0101',
  forestGreen: '#22C55E',
  activeGreen: '#4BC500',
  shamrockGreen: '#2e8acb',
  shamrockGreenHover: '#055e39',
  blue: '#3B82F6',
  lightPurple: '#728CF8',
  purple: '#2e8acb',
  purpleHover: '#1a147c',
  // grey300: '#e8e8e8',
  grey300: '#e5f1f9',
  grey400: '#CECECE',
  grey500: '#888',
  gunmetalBlue: '#2C3344',
  darkNavyBlue: '#0F172A',
  black: '#36445b',
  textBlack: '#000',
  navyBlue: '#2e8acb',
  neon: '#28c0c8',
  // new revamp
  drawerBg: 'linear-gradient(45deg,var(--cui-dark-start,#3c4b64) 0%,var(--cui-dark-stop,#212333 100%))',
  sidebarIconShadow: 'rgb(8 12 18 / 30%)',
  SidebarBrandBg: 'rgba(0, 0, 21, 0.15)',
  whiteColor_6: 'rgba(255, 255, 255, 0.6)',
  mainBg: '#f8f9fa',
  darkGrey: '#36445b',
  bgDarkGrey: '#2e8acb',
  grey600: '#2e8acb',
  greyText600: '#2e8acb',
  borderLight: '#f1f1f1',
  borderLightHead: '#f1f1f1',
  lightShadow: '#9fceed',
  shadow1: '#bfced9',
  cardHeadignText: '#344767',
  primeBlue: 'rgb(18, 43, 98)',
  formInputBorder: 'rgb(121 125 153 / 23%)',
  tableHeadText: '#8392ab',
  closeIconBg: '#b7b7b7',
  border1: '#d0d0d0',
  border2: 'rgba(79, 70, 229, 0.5)',
  border3: 'rgba(79, 70, 229, 0.6)',
  border4: '#e7eaec',
  btnBg1: '#1e293b',
  noteText: '#6e6d6d',
  graphGrid: '#c1d0d7',
  textColor1: '#6a7fa1',
  textColor2: '#61DAFB',
  textColor3: '#4CAF50',
  headerShadow: 'rgb(255 255 255 / 90%)',
  black_05: 'rgba(0,0,0,.05)',
  black_87: 'rgba(0, 0, 0, 0.87)',
  textWhite: '#ffffff',
  secondary: '#174566',
  tableHeaderBg: 'transparent',
  invertToggle: 'invert(1)',
  btnBlue: '#00A4FA',
  textgrey: '#8A90A2'
}
