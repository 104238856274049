import { Drawer, styled } from '@mui/material'
// import { colors } from 'theme/colors/index'

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  // background: colors.drawerBg,
  ...theme.mixins.toolbar
}))

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: theme.drawerWidth,
  flexShrink: 0,

  '& .MuiDrawer-paper': {
    width: theme.drawerWidth,
    boxSizing: 'border-box',
    borderRight: '0',

    '& .MuiList-root': {
      margin: '0 1rem',
      '& .MuiListItem-root': {
        // padding:"8px 12px 8px 46px",
        borderRadius: '8px',
        overflow: 'hidden',
        '& .MuiTypography-root': {
          fontSize: '1rem',
          fontWeight: '400'
        }
      },
      '& .Mui-selected': {
        backgroundColor: theme.colors.btnBlue
      }
    },
    '.sidebar-icon': {
      padding: '8px',
      minWidth: '0',
      marginRight: '8px'
    },
    '.MuiSvgIcon-root': {
      fill: theme.colors.textWhite,
      width: '1.125rem',
      height: '1.125rem'
    }
  },
  '& .MuiDrawer-root': {
    '& .MuiList-root': {
      margin: '0 1rem'
    }
  }
}))
