import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

/**
 * Service to update cms details
 */
export const updateCmsDetailsService = (cmsData) => {
  return axiosInstance(METHOD_TYPES.put, '/cms/update-cms', cmsData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.cmsDetailsUpdated
  })
}

/**
 * Service to get cms details
 */
export const getCmsDetailService = (cmsId) => {
  return axiosInstance(METHOD_TYPES.get, '/cms/cms-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { cmsId }
  })
}

/**
 * Service to create cms
 * @param {object} data - object contains all required properties to create new cms
 */
export const createCmsService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/cms/create-cms', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.cmsCreatedSuccessfully
  })
}

/**
 * Service to fetch all cms
 */
export const getAllCmsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/cms/all-cms', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to update cms status
 */
export const updateCmsStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/cms/update-cms-status', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.cmsStatusUpdatedSuccessfully
  })
}
