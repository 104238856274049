import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

/**
 * Service to update ranking-level details
 */
export const updateRankingLevelDetailsService = (rankData) => {
  return axiosInstance(METHOD_TYPES.put, '/ranking-level/update', rankData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.rankingDetailsUpdatedSuccessfully
  })
}

/**
 * Service to update ranking-level status
 */
export const updateRankingLevelStatusService = (rankStatusData) => {
  return axiosInstance(METHOD_TYPES.put, '/ranking-level/update-status', rankStatusData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.rankingStatusUpdatedSuccessfully
  })
}

/**
 * Service to create ranking level
 * @param {object} data - object contains all required properties to create new ranking-level
 */
export const createRankingLevelService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/ranking-level/create', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.rankingLevelCreatedSuccessfully
  })
}

/**
 * Service to fetch all ranking levels
 */
export const getAllRankingLevelsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/ranking-level/get', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
