import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'
import axiosInstance, { microServices } from 'network/apis'

/**
 * Service to get user info
 */
export const getUserInfoService = () => {
  return axiosInstance(METHOD_TYPES.get, 'admins/details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}
