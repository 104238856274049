import { createSlice } from '@reduxjs/toolkit'
import { fetchAllUpliftingWords } from 'redux-thunk/thunk/upliftingWords'

const initialState = {
  allUpliftingWords: null
}

const upliftingWordsSlice = createSlice({
  name: 'upliftingWordsSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUpliftingWords.fulfilled, (state, action) => {
        return {
          ...state,
          allUpliftingWords: action.payload.words
        }
      })
  }
})

export default upliftingWordsSlice.reducer
