import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllIndividualReferredUsersStatsService, getAllReferralBonusSettingsService, getAllReferralService, getAllReferredUsersService } from 'network/services/referral.service'

export const fetchAllReferrals = createAsyncThunk(
  'fetch/allReferrals',
  async (data, thunkApi) => {
    try {
      const res = await getAllReferralService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllReferredUsers = createAsyncThunk(
  'fetch/allReferredUsers',
  async (data, thunkApi) => {
    try {
      const res = await getAllReferredUsersService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllIndividualReferredUsersStats = createAsyncThunk(
  'fetch/allIndividualReferredUsersStats',
  async (data, thunkApi) => {
    try {
      const res = await getAllIndividualReferredUsersStatsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchReferralBonusSettings = createAsyncThunk(
  'fetch/allReferralBonusSettings',
  async (data, thunkApi) => {
    try {
      const res = await getAllReferralBonusSettingsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
