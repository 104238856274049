import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCrashGameReportService } from 'network/services/game.service'
import
{
  getCustomGameReportService,
  getCasinoGameReportService,
  getSportsBookGameReportService,
  getSqlReportData
} from 'network/services/report.service'

export const fetchCrashGameReport = createAsyncThunk(
  'fetch/crashGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getCrashGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchCasinoGameReport = createAsyncThunk(
  'fetch/casinoGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchCustomGameReport = createAsyncThunk(
  'fetch/customGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getCustomGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchSportsBookReport = createAsyncThunk(
  'fetch/sportsbookGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getSportsBookGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchSqlReportData = createAsyncThunk(
  'fetch/sqlReportData',
  async (data, thunkApi) => {
    try {
      const res = await getSqlReportData(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
