import { createAsyncThunk } from '@reduxjs/toolkit'
import { createRankingLevelService, getAllRankingLevelsService, updateRankingLevelDetailsService, updateRankingLevelStatusService } from 'network/services/rankingLevels.service'

/**
 * Get All Ranking Levels Thunk
 */
export const fetchAllRankingLevels = createAsyncThunk(
  'fetch/allRanks',
  async (data, thunkApi) => {
    try {
      const res = await getAllRankingLevelsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create New Ranking Thunk
 */
export const createRanking = createAsyncThunk(
  'create/reward',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createRankingLevelService(data)
      onSuccess(res.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Rank Level Details Thunk
 */
export const updateRankingLevel = createAsyncThunk(
  'update/rewardDetail',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateRankingLevelDetailsService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Change Rank Level Status Thunk
 */
export const updateRankLevelStatus = createAsyncThunk(
  'update/rankingLevelStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateRankingLevelStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
