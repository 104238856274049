import { createSlice } from '@reduxjs/toolkit'
import {
  fetchAllGameBackgroundTheme,
  fetchAllGameHeroTheme
} from 'redux-thunk/thunk/gameTheme'

const initialState = {
  // Hero state
  allHeroThemes: null,
  heroDetails: null,
  allHeroThemeLoading: false,
  // Background state
  allBackgroundThemes: null,
  backgroundDetails: null,
  allBackgroundThemeLoading: false

}

const gameThemeSlice = createSlice({
  name: 'gameThemeSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGameHeroTheme.pending, (state, action) => {
        return {
          ...state,
          allHeroThemeLoading: true
        }
      })
      .addCase(fetchAllGameHeroTheme.fulfilled, (state, action) => {
        return {
          ...state,
          allHeroThemes: action.payload,
          allHeroThemeLoading: false
        }
      })
      .addCase(fetchAllGameHeroTheme.rejected, (state, action) => {
        return {
          ...state,
          allHeroThemeLoading: false
        }
      })
      .addCase(fetchAllGameBackgroundTheme.pending, (state, action) => {
        return {
          ...state,
          allBackgroundThemeLoading: true
        }
      })
      .addCase(fetchAllGameBackgroundTheme.fulfilled, (state, action) => {
        return {
          ...state,
          allBackgroundThemes: action.payload,
          allBackgroundThemeLoading: false
        }
      })
      .addCase(fetchAllGameBackgroundTheme.rejected, (state, action) => {
        return {
          ...state,
          allBackgroundThemeLoading: false
        }
      })
  }
})

export default gameThemeSlice.reducer
