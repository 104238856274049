import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  approveWithdrawRequestService,
  getAllTransactionsService,
  getAllWithdrawRequestService,
  rejectWithdrawRequestService,
  getAllPaymentTransactionsService,
  getCoinPaymentsTransactionLinkService
} from 'network/services/transaction.service'
import { manualWalletDepositService } from 'network/services/wallet.service'
import { fetchPlayerDetails } from './players'

export const fetchAllTransactions = createAsyncThunk(
  'fetch/allTransactions',
  async (data, thunkApi) => {
    try {
      const res = await getAllTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllPaymentTransactions = createAsyncThunk(
  'fetch/payment-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getAllPaymentTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllWithdrawRequests = createAsyncThunk(
  'fetch/allWithdrawRequests',
  async (data, thunkApi) => {
    try {
      const res = await getAllWithdrawRequestService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const approveWithdrawRequest = createAsyncThunk(
  'approve/withdrawRequest',
  async ({ withdrawRequestId, onSuccess }, thunkApi) => {
    try {
      const res = await approveWithdrawRequestService({ withdrawRequestId })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const rejectWithdrawRequest = createAsyncThunk(
  'reject/withdrawRequest',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await rejectWithdrawRequestService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const depositManualAmount = createAsyncThunk(
  'wallet/manual-deposit',
  async ({ data, onComplete }, thunkApi) => {
    try {
      const res = await manualWalletDepositService(data)
      thunkApi.dispatch(fetchPlayerDetails(data.userId))
      onComplete()
      return res
    } catch (error) {
      onComplete()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCoinPaymentsTransactionLink = createAsyncThunk(
  'fetch/payment-transactions-link',
  async (data, thunkApi) => {
    try {
      const res = await getCoinPaymentsTransactionLinkService(data)
      window.open(res?.url, '_blank')
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
