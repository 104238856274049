import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createCmsService,
  getAllCmsService,
  getCmsDetailService,
  updateCmsDetailsService,
  updateCmsStatusService
} from 'network/services/cms.service'

/**
 * Get All Cms Thunk
 */
export const fetchAllCms = createAsyncThunk(
  'fetch/allCms',
  async (data, thunkApi) => {
    try {
      const res = await getAllCmsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchCmsDetails = createAsyncThunk(
  'fetch/cmsDetail',
  async (cmsId, thunkApi) => {
    try {
      const res = await getCmsDetailService(cmsId)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Cms Thunk
 */
export const createCms = createAsyncThunk(
  'create/cms',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createCmsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Cms Thunk
 */
export const updateCms = createAsyncThunk(
  'update/cmsDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateCmsDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Cms Status Thunk
 */
export const updateCmsStatus = createAsyncThunk(
  'update/CmsStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateCmsStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
