import { Grid } from '@mui/material'
import { ReactComponent as Login1Icon } from 'assets/images/login1.svg'
import { ReactComponent as LoginBg2Icon } from 'assets/images/loginBg2.svg'
import CustomButton from 'components/ui-kit/Button/index'
import CustomTextField from 'components/ui-kit/TextField'
import CustomTypography from 'components/ui-kit/Typography'
import { LOADER_TYPE } from 'utils/constants'
import { useSelector } from 'react-redux'
import { LoginContainer, LoginIconContainer, RootLoginContainer, WelcomeTextContainer } from './styles'
import { adminLogin } from 'redux-thunk/thunk/auth'
import { useLoginController } from './controller/useLoginController'
import AppLoader from 'components/ui-kit/AppLoader'
import { Link } from 'react-router-dom'
import { ROUTE_PATHS } from 'utils/constants/index'

const Login = () => {
  const {
    t,
    otpRequired,
    otp,
    setOtp,
    handleSubmit,
    values,
    handleChange,
    errors
  } = useLoginController({ adminLogin })

  const loginLoading = useSelector(state => state.loader.submitButtonLoader)

  return (
    <Grid container>
      <Grid item xs={12} lg={6} sx={{ position: 'relative' }}>
        <RootLoginContainer>
          <LoginContainer sx={{ boxShadow: '0 0 105px lightgray', borderRadius: '25px', backgroundColor: (theme) => theme.colors.white }}>
            <CustomTypography
              value={t('login')}
              variant='h5'
              sx={{
                textAlign: 'center', fontWeight: '600', color: (theme) => theme.colors.black
              }}
            />
            <br />
            <CustomTextField
              sx={{ marginBottom: '20px' }}
              label={t('email')}
              placeholder={t('email')}
              variant='outlined'
              fullWidth
              id='email'
              name='email'
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              helperText={errors.email}
              enablevalidation={Boolean(values.email || errors.email)}
            />
            <CustomTextField
              sx={{ marginBottom: '10px' }}
              label={t('password')}
              type='password'
              placeholder={t('password')}
              variant='outlined'
              name='password'
              id='password'
              value={values.password}
              onChange={handleChange}
              error={errors.password}
              helperText={errors.password}
              enablevalidation={Boolean(values.password || errors.password)}
            />

            {otpRequired && (
              <CustomTextField
                label={t('Enter OTP')}
                variant='outlined'
                fullWidth
                id='otp'
                name='otp'
                type='number'
                value={otp}
                sx={{ letterSpacing: '10px' }}
                onChange={(e) => {
                  const value = e.target.value
                  String(value).length <= 6 && setOtp(value)
                }}
              />)}

            <Link
              to={ROUTE_PATHS.forgotPassword}
              style={{
                textDecoration: 'none',
                textAlign: 'right',
                marginBottom: '22px',
                fontSize: '0.75rem'
              }}
            >
              <CustomTypography
                value={t('forgotPassword')}
                sx={{ fontSize: '0.825rem', color: (theme) => theme.colors.black }}
              />
            </Link>

            <CustomButton
              variant='contained'
              disabled={Boolean(errors.email) || Boolean(errors.password) || !values.email.length || loginLoading}
              onClick={handleSubmit}
              sx={{ backgroundColor: (theme) => theme.colors.btnBg1, minWidth: '266px' }}
            >
              {
                loginLoading
                  ? <AppLoader variant={LOADER_TYPE.PULSE} size={5} />
                  : <CustomTypography sx={{ fontWeight: 'bold' }} value={t('login')} />
              }
            </CustomButton>
          </LoginContainer>
        </RootLoginContainer>

        <LoginIconContainer>
          <Login1Icon style={{ height: '100%', width: '100%' }} />
        </LoginIconContainer>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: { xs: 'none', lg: 'block' },
          position: 'relative'
        }}
      >
        <WelcomeTextContainer>
          <CustomTypography
            variant='h3'
            align='center'
            value={t('WelcomeToOurCommunity')}
            sx={{ color: (theme) => theme.colors.textWhite, padding: '20px', fontWeight: '700' }}
          />
        </WelcomeTextContainer>

        <LoginIconContainer>
          <LoginBg2Icon style={{ height: '100%', width: '100%' }} />
        </LoginIconContainer>
      </Grid>
    </Grid>
  )
}

export default Login
