import { createSlice } from '@reduxjs/toolkit'
import { fetchAllBanners, fetchBannersDetails } from 'redux-thunk/thunk/banner'

const initialState = {
  allBanners: null,
  bannerDetail: null
}

const bannerSlice = createSlice({
  name: 'bannerSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBanners.fulfilled, (state, action) => {
        return {
          ...state,
          allBanners: action.payload
        }
      })
      .addCase(fetchBannersDetails.fulfilled, (state, action) => {
        return {
          ...state,
          bannerDetail: action.payload
        }
      })
  }
})

export default bannerSlice.reducer
