import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createGameHeroThemeService,
  getAllGameHeroThemeService,
  updateGameHeroThemeService,
  updateGameHeroThemeStatusService,
  getAllGameBackgroundThemeService,
  updateGameBackgroundThemeStatusService,
  updateGameBackgroundThemeService,
  createGameBackgroundThemeService,
  setDefaultGameThemeService
} from 'network/services/game-theme-setting.service'

/**
 * Get All Game Hero Theme Thunk
 */
export const fetchAllGameHeroTheme = createAsyncThunk(
  'fetch/gameTheme/hero',
  async (data, thunkApi) => {
    try {
      const res = await getAllGameHeroThemeService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Game Hero Theme Thunk
 */
export const createGameHeroTheme = createAsyncThunk(
  'create/gameTheme/hero',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createGameHeroThemeService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Game Hero Theme Thunk
 */
export const updateGameHeroTheme = createAsyncThunk(
  'update/gameTheme/hero',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateGameHeroThemeService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Change Game Hero Theme Status Thunk
 */
export const updateGameHeroThemeStatus = createAsyncThunk(
  'update/gameTheme/hero-status',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateGameHeroThemeStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Get All Game Background Theme Thunk
 */
export const fetchAllGameBackgroundTheme = createAsyncThunk(
  'fetch/gameTheme/background',
  async (data, thunkApi) => {
    try {
      const res = await getAllGameBackgroundThemeService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Game Background Theme Thunk
 */
export const createGameBackgroundTheme = createAsyncThunk(
  'create/gameTheme/Background',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createGameBackgroundThemeService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Game Background Theme Thunk
 */
export const updateGameBackgroundTheme = createAsyncThunk(
  'update/gameTheme/background',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateGameBackgroundThemeService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Change Game Background Theme Status Thunk
 */
export const updateGameBackgroundThemeStatus = createAsyncThunk(
  'update/gameTheme/background-status',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateGameBackgroundThemeStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Change Game Background Theme Status Thunk
 */
export const resetDefaultThemeSetting = createAsyncThunk(
  'reset/set-default-game-theme',
  async ({ data, onFinish }, thunkApi) => {
    try {
      const res = await setDefaultGameThemeService(data)
      onFinish()
      return res
    } catch (error) {
      onFinish()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
