import { useDispatch } from 'react-redux'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import AppHeader from 'components/ui-kit/AppHeader/index'
import { RightSideOptionContainer, MaterialUISwitch } from './styles'
import { handleDrawerToggle } from 'redux-thunk/redux/hamSlice'
import { handleLanguageChange } from 'redux-thunk/redux/authSlice'
import { useHeaderController } from './controller/useHeaderController'
import CustomButton from 'components/ui-kit/Button/index'
import CustomTypography from 'components/ui-kit/Typography/index'
import { ROUTE_PATHS } from 'utils/constants/index'
import LanguageMenu from './components/LanguageMenu/index'
import { Box, FormGroup, FormControlLabel } from '@mui/material'

const Header = () => {
  const dispatch = useDispatch()
  const {
    t,
    navigate,
    anchorElLanguage,
    toggleLanguageMenu,
    handleChangeLanguage,
    logoutHandler,
    toggleThemeHandler,
    selectedLanguage,
    isSideNavOpen,
    currentTheme
  } = useHeaderController({
    handleLanguageChange
  })

  return (
    <AppHeader
      openDrawer={isSideNavOpen}
      handleDrawer={() => dispatch(handleDrawerToggle())}
      left={null}
      right={
        <RightSideOptionContainer>
          <Box
            // onClick={toggleThemeHandler}
            sx={{ px: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <FormGroup>
              <FormControlLabel
                sx={{ mr: 0 }}
                control={<MaterialUISwitch />}
                label={<Box sx={{ mr: 0.8, fontSize: 14, textTransform: 'capitalize' }}>{t(currentTheme)}</Box>}
                onChange={toggleThemeHandler}
              />
            </FormGroup>
          </Box>

          <LanguageMenu
            toggleLanguageMenu={toggleLanguageMenu}
            anchorElLanguage={anchorElLanguage}
            handleChangeLanguage={handleChangeLanguage}
            selectedLanguage={selectedLanguage}
          />
          <CustomButton
            variant='contained'
            sx={{
              backgroundColor: (theme) => theme.colors.grey600,
              minWidth: '130px',
              borderRadius: '0.2rem',
              '&:hover': {
                backgroundColor: (theme) => theme.colors.bgDarkGrey
              }
            }}
            onClick={() => navigate(ROUTE_PATHS.settings)}
          >
            <ManageAccountsIcon sx={{ fill: (theme) => theme.colors.textWhite, marginRight: '10px' }} />
            <CustomTypography
              sx={{ fontWeight: '600' }}
              value={t('profile')}
            />
          </CustomButton>
          <CustomButton
            variant='contained'
            id='logout-action'
            sx={{
              backgroundColor: (theme) => theme.colors.grey600,
              minWidth: '130px',
              borderRadius: '0.2rem',
              '&:hover': {
                backgroundColor: (theme) => theme.colors.bgDarkGrey
              }
            }}
            onClick={logoutHandler}
          >
            <LogoutIcon sx={{ fill: (theme) => theme.colors.textWhite, marginRight: '10px' }} />
            <CustomTypography
              sx={{ fontWeight: '600' }}
              value={t('logout')}
            />
          </CustomButton>
        </RightSideOptionContainer>
      }
    />
  )
}

export default Header
