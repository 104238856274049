import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

export const getAllGameHeroThemeService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/games/game-themes/list-hero', {}, {
    server: microServices.USER_URL,
    params: data,
    loader: null
  })
}

export const getGameHeroThemeService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/games/game-themes/hero-details', {}, {
    server: microServices.USER_URL,
    params: data
  })
}

export const createGameHeroThemeService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/games/game-themes/create-hero', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.gameHeroCreatedSuccessfully
  })
}

export const updateGameHeroThemeService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/games/game-themes/update-hero', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.gameHeroUpdatedSuccessfully
  })
}

export const deleteGameHeroThemeService = (gameHeroId) => {
  return axiosInstance(METHOD_TYPES.delete, `/games/game-themes/${gameHeroId}/delete-hero`, {}, {
    server: microServices.USER_URL
  })
}

export const updateGameHeroThemeStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/games/game-themes/update-hero-status', data, {
    server: microServices.USER_URL,
    successMessage: SuccessMessage.gameHeroStatusUpdatedSuccessfully
  })
}

export const getAllGameBackgroundThemeService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/games/game-themes/list-backgrounds', {}, {
    server: microServices.USER_URL,
    params: data,
    loader: null
  })
}

export const getGameBackgroundThemeService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/games/game-themes/background-details', {}, {
    server: microServices.USER_URL,
    params: data
  })
}

export const createGameBackgroundThemeService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/games/game-themes/create-background', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.gameBackgroundUpdatedSuccessfully
  })
}

export const updateGameBackgroundThemeService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/games/game-themes/update-background', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.gameBackgroundUpdatedSuccessfully
  })
}

export const deleteGameBackgroundThemeService = (id) => {
  return axiosInstance(METHOD_TYPES.delete, `/games/game-themes/${id}/delete-background`, {}, {
    server: microServices.USER_URL
  })
}

export const updateGameBackgroundThemeStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/games/game-themes/update-background-status', data, {
    server: microServices.USER_URL,
    successMessage: SuccessMessage.gameHeroStatusUpdatedSuccessfully
  })
}

export const setDefaultGameThemeService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/games/game-themes/reset', data, {
    server: microServices.USER_URL,
    loader: null,
    successMessage: SuccessMessage.resetDefaultThemeSuccessfully
  })
}
