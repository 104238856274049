import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  blockReportedUserService,
  getAllReportedUserService,
  getIndividualReportedUserService,
  unBlockReportedUserService
} from 'network/services/reported-user.service'

/**
 * Get All Reported Users Thunk
 */
export const fetchAllReportedUsers = createAsyncThunk(
  'fetch/allReportedUsers',
  async (data, thunkApi) => {
    try {
      const res = await getAllReportedUserService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchIndividualReportedUser = createAsyncThunk(
  'fetch/individualReportedUser',
  async (data, thunkApi) => {
    try {
      const res = await getIndividualReportedUserService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Unblock Reported User Thunk
 */
export const unBlockReportedUser = createAsyncThunk(
  'unblock/reportedUser',
  async ({ data }, thunkApi) => {
    try {
      const res = await unBlockReportedUserService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Block Reported User Thunk
 */
export const blockReportedUser = createAsyncThunk(
  'block/reportedUser',
  async ({ data }, thunkApi) => {
    try {
      const res = await blockReportedUserService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)
