import { createSlice } from '@reduxjs/toolkit'
import { fetchAllThemes, fetchThemesDetails } from 'redux-thunk/thunk/themes'

const initialState = {
  allThemes: null,
  themeDetail: null
}

const themeSlice = createSlice({
  name: 'themeSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllThemes.fulfilled, (state, action) => {
        return {
          ...state,
          allThemes: action.payload
        }
      })
      .addCase(fetchThemesDetails.fulfilled, (state, action) => {
        return {
          ...state,
          themeDetail: action.payload
        }
      })
  }
})

export default themeSlice.reducer
