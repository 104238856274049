import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import CustomTypography from '../Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

const LabelContainer = ({
  showPassword,
  setShowPassword,
  setCustomizeType,
  isPasswordField,
  children,
  type,
  label,
  minHeight,
  required
}) => {
  switch (type) {
    case 'none':
      return children
    case 'filter':
      return (
        <div type={type}>
          <div>
            <CustomTypography
              sx={{
                color: (theme) => theme.colors.gunmetalBlue,
                fontSize: '12px',
                fontWeight: 600
              }}
              variant='subtitle1'
              value={`${label}`}
            />
          </div>
          {children}
        </div>
      )

    default:
      return (
        <Box>
          <Box height='24px' textAlign='start'>
            <CustomTypography
              sx={{ color: (theme) => theme.colors.gunmetalBlue }}
              variant='subtitle1'
              value={label}
            />
            {required && (
              <CustomTypography
                sx={{ color: (theme) => theme.colors.red }}
                variant='subtitle1'
                value='*'
              />
            )}
            <span
              style={{
                float: 'right',
                position: 'relative',
                top: '1.813rem',
                zIndex: '1',
                left: '-3rem',
                height: '25px'
              }}
            >
              {isPasswordField &&
                (!showPassword
                  ? (
                    <VisibilityIcon
                      sx={{ fill: '#989898', width: '20px' }}
                      onClick={() => {
                        setShowPassword(true)
                        setCustomizeType('text')
                      }}
                    />
                    )
                  : (
                    <VisibilityOffIcon
                      sx={{ fill: '#989898', width: '20px' }}
                      onClick={() => {
                        setShowPassword(false)
                        setCustomizeType('password')
                      }}
                    />
                    ))}
            </span>
          </Box>
          {children}
        </Box>
      )
  }
}

export default LabelContainer

LabelContainer.defaultProps = {
  type: 'default',
  label: '',
  minHeight: '85px'
}

LabelContainer.propTypes = {
  type: PropTypes.oneOf(['filter', 'default', 'dateFilter', 'none']),
  label: PropTypes.string,
  children: PropTypes.element.isRequired,
  height: PropTypes.string
}
