import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createBannerService,
  getAllBannersService,
  getBannerDetailService,
  updateBannerDetailsService
} from 'network/services/banners.service'

/**
 * Get All Banners Thunk
 */
export const fetchAllBanners = createAsyncThunk(
  'fetch/allBanners',
  async (data, thunkApi) => {
    try {
      const res = await getAllBannersService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchBannersDetails = createAsyncThunk(
  'fetch/bannerDetail',
  async (bannerId, thunkApi) => {
    try {
      const res = await getBannerDetailService(bannerId)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Banner Thunk
 */
export const createBanner = createAsyncThunk(
  'create/banner',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createBannerService(data)
      onSuccess(res.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Banner Thunk
 */
export const updateBanner = createAsyncThunk(
  'update/bannerDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateBannerDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
