import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createAffiliateService,
  createAffiliateSettingsService,
  deleteAffiliateService,
  getAffiliateStatsService,
  getAllAffiliatePlayerService,
  getAllAffiliateService,
  getAllAffiliateTransactionsService,
  getAllCommissionSettlementService,
  getAllCommissionsService,
  getIndividualAffiliatePlayerStatsService,
  settleAffiliateCommissionService,
  updateAffiliateCommissionService,
  updateAffiliatePlayerStatusService
} from 'network/services/affiliates.service'

export const fetchAllAffiliates = createAsyncThunk(
  'fetch/allAffiliates',
  async (data, thunkApi) => {
    try {
      const res = await getAllAffiliateService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllAffiliateTransactions = createAsyncThunk(
  'fetch/allAffiliateTransactions',
  async (data, thunkApi) => {
    try {
      const res = await getAllAffiliateTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllCommissions = createAsyncThunk(
  'fetch/allCommissions',
  async (data, thunkApi) => {
    try {
      const res = await getAllCommissionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createAffiliate = createAsyncThunk(
  'create/affiliate',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createAffiliateService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const deleteAffiliate = createAsyncThunk(
  'delete/affiliate',
  async ({ affiliateId, onSuccess }, thunkApi) => {
    try {
      const res = await deleteAffiliateService(affiliateId)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllAffiliatedPlayers = createAsyncThunk(
  'fetch/allAffiliatesPlayers',
  async (data, thunkApi) => {
    try {
      const res = await getAllAffiliatePlayerService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateAffiliatePlayerStatus = createAsyncThunk(
  'update/adminStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateAffiliatePlayerStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createAffiliateSettings = createAsyncThunk(
  'create/affiliateCommission',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createAffiliateSettingsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateAffiliateCommission = createAsyncThunk(
  'update/affiliateCommission',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateAffiliateCommissionService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const settleAffiliateCommission = createAsyncThunk(
  'create/affiliateCommission',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await settleAffiliateCommissionService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAffiliateStats = createAsyncThunk(
  'fetch/affiliateStats',
  async (data, thunkApi) => {
    try {
      const res = await getAffiliateStatsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllCommissionSettlement = createAsyncThunk(
  'fetch/allCommissionSettlement',
  async (data, thunkApi) => {
    try {
      const res = await getAllCommissionSettlementService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchIndividualAffiliatePlayerStats = createAsyncThunk(
  'fetch/individualAffiliatePlayerStats',
  async (data, thunkApi) => {
    try {
      const res = await getIndividualAffiliatePlayerStatsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
