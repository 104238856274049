import { createSlice } from '@reduxjs/toolkit'
import { fetchAllReportedUsers, fetchIndividualReportedUser } from 'redux-thunk/thunk/reportedUser'

const initialState = {
  allReportedUsers: null,
  individualReportedUser: null
}

const reportedUserSlice = createSlice({
  name: 'reportedUser',
  initialState,
  reducers: {
    setAllReportedUsers: (state, action) => {
      state.allReportedUsers = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllReportedUsers.fulfilled, (state, action) => {
      state.allReportedUsers = action.payload
    })
    builder.addCase(fetchIndividualReportedUser.fulfilled, (state, action) => {
      state.individualReportedUser = action.payload
    })
  }
})

export const { setAllReportedUsers } = reportedUserSlice.actions

export default reportedUserSlice.reducer
