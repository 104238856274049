import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createPromotionService,
  getAllPromotionsService,
  updatePromotionDetailsService,
  updatePromotionStatusService
} from 'network/services/promotions.service'

/**
 * Get All Promotions Thunk
 */
export const fetchAllPromotions = createAsyncThunk(
  'fetch/allPromotions',
  async (data, thunkApi) => {
    try {
      const res = await getAllPromotionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Promotion Thunk
 */
export const createPromotion = createAsyncThunk(
  'create/promotion',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createPromotionService(data)
      onSuccess(res.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Promotion Thunk
 */
export const updatePromotion = createAsyncThunk(
  'update/promotionDetail',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updatePromotionDetailsService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Change Promotion Status Thunk
 */
export const updatePromotionStatus = createAsyncThunk(
  'update/promotionStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updatePromotionStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
