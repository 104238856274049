import { METHOD_TYPES } from 'utils/constants'
import axiosInstance, { microServices } from 'network/apis'

export const getRegisteredUserCountService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/dashboard/registered-user', {}, {
    server: microServices.USER_URL,
    loader: null,
    params: data
  })
}

export const getTransactionStatisticService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/dashboard/transactions', {}, {
    server: microServices.USER_URL,
    loader: null,
    params: data
  })
}

export const getPlacedBetCountService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/dashboard/placed-bets', {}, {
    server: microServices.USER_URL,
    loader: null,
    params: data
  })
}

export const getLoggedInUserCountService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/dashboard/loggedin-user', {}, {
    server: microServices.USER_URL,
    loader: null,
    params: data
  })
}

export const getPlayersReport = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/dashboard/player-report', {}, {
    server: microServices.USER_URL,
    loader: null,
    params: data
  })
}

export const getFinancialConditionReport = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/dashboard/financial-report', {}, {
    server: microServices.USER_URL,
    loader: null,
    params: data
  })
}

export const getPAndLReport = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/dashboard/pnl-report', {}, {
    server: microServices.USER_URL,
    loader: null,
    params: data
  })
}
