import { createSlice } from '@reduxjs/toolkit'
import {
  fetchAllDepositBonus,
  fetchAllFreeSpinBonus,
  fetchAllJoiningBonus,
  fetchAllReferralBonus,
  fetchAllRewards,
  getOtherBonusDetails
} from 'redux-thunk/thunk/bonus'

const initialState = {
  allJoiningBonus: null,
  joiningBonusListLoading: false,
  allReferralBonus: null,
  referralBonusListLoading: false,
  depositBonus: null,
  freeSpinBonus: null,
  bonusDetails: null,
  loading: false,
  allRewards: null
}

const bonusSlice = createSlice({
  name: 'bonusSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getOtherBonusDetails.fulfilled, (state, action) => {
        return {
          ...state,
          bonusDetails: action.payload.bonus,
          loading: false
        }
      })
      .addCase(getOtherBonusDetails.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(getOtherBonusDetails.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(fetchAllDepositBonus.fulfilled, (state, action) => {
        return {
          ...state,
          depositBonus: action.payload
        }
      })
      .addCase(fetchAllFreeSpinBonus.fulfilled, (state, action) => {
        return {
          ...state,
          freeSpinBonus: action.payload
        }
      })
      .addCase(fetchAllJoiningBonus.pending, (state, action) => {
        return {
          ...state,
          joiningBonusListLoading: true
        }
      })
      .addCase(fetchAllJoiningBonus.fulfilled, (state, action) => {
        return {
          ...state,
          allJoiningBonus: action.payload.joiningBonuses,
          joiningBonusListLoading: false
        }
      })
      .addCase(fetchAllJoiningBonus.rejected, (state, action) => {
        return {
          ...state,
          joiningBonusListLoading: false
        }
      })
      .addCase(fetchAllReferralBonus.pending, (state, action) => {
        return {
          ...state,
          referralBonusListLoading: true
        }
      })
      .addCase(fetchAllReferralBonus.fulfilled, (state, action) => {
        return {
          ...state,
          allReferralBonus: action.payload.referralBonuses,
          referralBonusListLoading: false
        }
      })
      .addCase(fetchAllReferralBonus.rejected, (state, action) => {
        return {
          ...state,
          referralBonusListLoading: false
        }
      })
      .addCase(fetchAllRewards.fulfilled, (state, action) => {
        return {
          ...state,
          allRewards: action.payload
        }
      })
  }
})

export default bonusSlice.reducer
