import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllWithdrawRequestService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/withdraw-request/all-withdraw-requests', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const approveWithdrawRequestService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/withdraw-request/approved', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const rejectWithdrawRequestService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/withdraw-request/rejected', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getAllPaymentTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/payment-transaction', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getCoinPaymentsTransactionLinkService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/get-transaction-info', {}, {
    server: microServices.USER_URL,
    params: data
  })
}
