import * as yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

export const useLoginController = ({ adminLogin }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [otp, setOtp] = useState('')
  const [otpRequired, setOtpRequired] = useState(false)

  const loginSchema = yup.object({
    email: yup
      .string()
      .label(t('email'))
      .email(t('invalidEmailFormat'))
      .required((props) => t('isRequired', props)),
    password: yup
      .string()
      .label(t('password'))
      .min(8, (props) => t('mustbeAtleastCharacters', { ...props, minNumber: 8 }))
      .max(16, (props) => t('cannotExceedCharacters', { ...props, maxNumber: 16 }))
      .required((props) => t('isRequired', props))
  })

  const { handleSubmit, values, handleChange, errors } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginSchema,
    onSubmit: async ({ email, password }) => {
      dispatch(adminLogin({
        token: Number(otp),
        email,
        password,
        setOtpRequired,
        navigate
      }))
    }
  })

  return {
    otpRequired,
    otp,
    setOtp,
    handleSubmit,
    values,
    handleChange,
    errors,
    t
  }
}
