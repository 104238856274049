import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllGameSettingsService, updateGameSettingsService, getAllGamesService, getAllSportsbookGamesService, getGamesProviderService, getGamesAggregatorsService, getSportsbettingTransactionsService, getCasinoTransactionsService, updateGameStatusService, updateGameProviderDataService, getAllSmartSoftCasinoGamesService, createGameAssetService, getCasinoCatagoriesService, updateCasinoCategoryStatusService, createGameCategoryAssetService, createGameCategoryService, updateGameCategoryService, updateGameProviderStatusService, getCryptoBetTransactionsService, getRollerCoasterTransactionsService, updateCategorySortOrderService, updateProviderSortOrderService, updateGamesSortOrderService, updateGamesCategoryService } from 'network/services/game.service'
import { setCasinoGames } from 'redux-thunk/redux/gameSettingSlice'

/**
 * Fetch all game settings
 */
export const fetchAllGameSetting = createAsyncThunk(
  'fetch/gameSettings',
  async (_, thunkApi) => {
    try {
      const res = await getAllGameSettingsService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update game settings
 */
export const updateGameSetting = createAsyncThunk(
  'update/gameSettings',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateGameSettingsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch all games
 */
export const fetchAllGames = createAsyncThunk(
  'fetch/games',
  async (data, thunkApi) => {
    try {
      const res = await getAllGamesService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch all Sportsbook games
 */
export const fetchSportsbookGames = createAsyncThunk(
  'fetch/SportsbookGames',
  async (data, thunkApi) => {
    try {
      const res = await getAllSportsbookGamesService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch games providers
 */
export const fetchGamesProvider = createAsyncThunk(
  'fetch/games-providers',
  async (data, thunkApi) => {
    try {
      const res = await getGamesProviderService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch games aggregators
 */
export const fetchGamesAggregators = createAsyncThunk(
  'fetch/games-aggregators',
  async (data, thunkApi) => {
    try {
      const res = await getGamesAggregatorsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch sportsbetting transactions
 */
export const fetchSportsbettingTransactions = createAsyncThunk(
  'fetch/sportsbetting-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getSportsbettingTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch casino transactions
 */
export const fetchCasinoTransactions = createAsyncThunk(
  'fetch/casino-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch crypto-bet transactions
 */
export const fetchCryptoBetTransactions = createAsyncThunk(
  'fetch/cryptoBet-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getCryptoBetTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch rollerCoaster transactions
 */
export const fetchRollerCoasterTransactions = createAsyncThunk(
  'fetch/rollerCoaster-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getRollerCoasterTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Game Status
 */
export const updateGameStatus = createAsyncThunk(
  'update/playerStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateGameStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Provider Status
 */
export const updateProviderData = createAsyncThunk(
  'update/playerStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateGameProviderDataService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch all Smartsoft Casino games
 */
export const fetchAllSmartSoftCasinoGames = createAsyncThunk(
  'fetch/smartsoft-casino-games',
  async (data, thunkApi) => {
    try {
      const res = await getAllSmartSoftCasinoGamesService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Upload casino game image
 */
export const updateCasinoGameAsset = createAsyncThunk(
  'create/upload-casino-game-img',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createGameAssetService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Fetch Casino categories
 */
export const fetchCasinoCatagories = createAsyncThunk(
  'fetch/casino-categories',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoCatagoriesService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Provider Status
 */
export const updateCategoryStatus = createAsyncThunk(
  'update/category-status',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateCasinoCategoryStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Upload casino game category image
 */
export const updateCasinoGameCategoryAsset = createAsyncThunk(
  'create/upload-casino-game-category-img',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createGameCategoryAssetService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Upload casino game category image
 */
export const createCategory = createAsyncThunk(
  'create/create-category',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createGameCategoryService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Upload casino game category image
 */
export const updateCategory = createAsyncThunk(
  'create/update-category',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateGameCategoryService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Provider Status
 */
export const updateProviderStatus = createAsyncThunk(
  'update/playerStatusOnly',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateGameProviderStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateCategorySortOrder = createAsyncThunk(
  'update/category-sort-order',
  async ({ data }, thunkApi) => {
    try {
      const res = await updateCategorySortOrderService(data)
      // onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateProviderSortOrder = createAsyncThunk(
  'update/provider-sort-order',
  async ({ data }, thunkApi) => {
    try {
      const res = await updateProviderSortOrderService(data)
      // onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateGamesSortOrder = createAsyncThunk(
  'update/games-sort-order',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateGamesSortOrderService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateGamesCategory = createAsyncThunk(
  'update/games-category',
  async ({ data: { casinoGameId, gameCategoryId, categoryName }, onSuccess }, thunkApi) => {
    try {
      const res = await updateGamesCategoryService({ casinoGameId, gameCategoryId })
      const { casinoGames } = thunkApi?.getState()?.gameSetting
      thunkApi?.dispatch(setCasinoGames(
        {
          ...casinoGames,
          rows: casinoGames?.rows?.map(game => casinoGameId === game?.id
            ? ({
                ...game,
                gameCategoryId,
                gameCategory: { ...game?.gameCategory, gameCategory: categoryName }
              })
            : game)
        }))
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
