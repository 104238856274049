import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

/**
 * Service to block reported user details
 */
export const blockReportedUserService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/reported-user/block', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.reportedUserBlockedSuccessfully
  })
}

/**
 * Service to unblock reported user
 * @param {object} data - object contains all required properties to unblock reported user
 */
export const unBlockReportedUserService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/reported-user/unblock', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.reportedUserUnBlockedSuccessfully
  })
}

/**
 * Service to fetch all reported users
 */
export const getAllReportedUserService = () => {
  return axiosInstance(METHOD_TYPES.get, '/reported-user/get', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getIndividualReportedUserService = (id) => {
  return axiosInstance(METHOD_TYPES.get, '/reported-user/get', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: id
  })
}
