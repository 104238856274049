import { createSlice } from '@reduxjs/toolkit'
import { fetchAllSponsors, fetchSponsorsDetails } from 'redux-thunk/thunk/sponsor'

const initialState = {
  allSponsors: null,
  sponsorDetail: null
}

const sponsorSlice = createSlice({
  name: 'sponsorSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSponsors.fulfilled, (state, action) => {
        return {
          ...state,
          allSponsors: action.payload
        }
      })
      .addCase(fetchSponsorsDetails.fulfilled, (state, action) => {
        return {
          ...state,
          sponsorDetail: action.payload
        }
      })
  }
})

export default sponsorSlice.reducer
