import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getLoggedInUserCountService,
  getPlacedBetCountService,
  getRegisteredUserCountService,
  getTransactionStatisticService,
  getPlayersReport,
  getFinancialConditionReport,
  getPAndLReport
} from 'network/services/dashboard.service'

export const fetchDashboardStatistics = createAsyncThunk(
  'fetch/dashboard-statistics',
  async (data, thunkApi) => {
    try {
      const res1 = await getRegisteredUserCountService(data)
      const res2 = await getTransactionStatisticService(data)
      const res3 = await getPlacedBetCountService(data)
      const res4 = await getLoggedInUserCountService(data)
      return { ...res1, ...res2, ...res3, ...res4 }
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchDashboardData = createAsyncThunk(
  'fetch/players-data',
  async (data, thunkApi) => {
    try {
      const res = await getPlayersReport(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchFinancialReport = createAsyncThunk(
  'fetch/financial-report',
  async (data, thunkApi) => {
    try {
      const res = await getFinancialConditionReport(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchPAndLReport = createAsyncThunk(
  'fetch/pnl-report',
  async (data, thunkApi) => {
    try {
      const res = await getPAndLReport(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
