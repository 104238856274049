import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { sidebarOptions } from './sidebarOptions'
import { useSidebarController } from './controller/useSidebarController'
import CustomList from './components/CustomList/index'
import AppDrawer from 'components/ui-kit/AppDrawer/index'
import { handleDrawerToggle } from 'redux-thunk/redux/hamSlice'

const Sidebar = () => {
  const dispatch = useDispatch()
  const { t, location, expandedMenuItem, handleDrawerOptions } = useSidebarController()
  const isSideBarOpen = useSelector(state => state.ham.openHam)

  return (
    <AppDrawer
      testid='sidebar'
      openDrawer={isSideBarOpen}
      handleDrawer={() => dispatch(handleDrawerToggle())}
      text={t('adminUser')}
    // isMobileView={isMobileView}
    >
      <Box>
        <CustomList
          t={t}
          location={location}
          list={sidebarOptions}
          handleDrawerOptions={handleDrawerOptions}
          expandedMenuItem={expandedMenuItem}
        />
        {/* <ContactInfo t={t} /> */}
      </Box>
    </AppDrawer>
  )
}

export default Sidebar
