import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

/**
 * Service to update sponsor details
 */
export const updateSponsorDetailsService = (sponsorData) => {
  return axiosInstance(METHOD_TYPES.put, '/ad-sponsors/update', sponsorData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.sponsorDetailsUpdated
  })
}

/**
 * Service to get sponsor details
 */
export const getSponsorDetailService = (sponsorId) => {
  return axiosInstance(METHOD_TYPES.get, '/ad-sponsors/details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { sponsorId }
  })
}

/**
 * Service to update sponsor status
 */
export const updateSponsorStatusService = (sponsorUserData) => {
  return axiosInstance(METHOD_TYPES.put, '/ad-sponsors/update-status', sponsorUserData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.sponsorStatusUpdatedSuccessfully
  })
}

/**
 * Service to create sponsor
 * @param {object} data - object contains all required properties to create new sponsor
 */
export const createSponsorService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/ad-sponsors/create', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.sponsorCreatedSuccessfully
  })
}

/**
 * Service to fetch all sponsor
 */
export const getAllSponsorsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/ad-sponsors/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
