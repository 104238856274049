import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllKycVerificationRequestService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/kyc-users', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const approvedKycVerificationRequestService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/users/approved-kyc', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getUserKycVerificationDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/get-user-kyc-levels', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
