import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllUpliftingWordsService, updateUpliftingWordDetailService, updateUpliftingWordStatusService } from 'network/services/uplifting-words.service'

/**
 * Get All UpliftingWords Thunk
 */
export const fetchAllUpliftingWords = createAsyncThunk(
  'fetch/allUpliftingWords',
  async (_, thunkApi) => {
    try {
      const res = await getAllUpliftingWordsService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update UpliftingWord Thunk
 */
export const updateUpliftingWordDetail = createAsyncThunk(
  'update/upliftingWord',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateUpliftingWordDetailService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Change UpliftingWord Status Thunk
 */
export const updateUpliftingWordStatus = createAsyncThunk(
  'update/upliftingWordStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateUpliftingWordStatusService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
