import { METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

export const manualWalletDepositService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/wallets/manual-currency-deposit', data, {
    server: microServices.USER_URL,
    loader: null,
    successMessage: SuccessMessage.manualAmountDepositedSuccessfully
  })
}
