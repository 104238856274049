import { createSlice } from '@reduxjs/toolkit'
import { fetchAllCryptoInstruments } from 'redux-thunk/thunk/cryptoInstrument'

const initialState = {
  allCryptoInstrument: null
}

const cryptoInstrumentSlice = createSlice({
  name: 'cryptoInstrumentSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCryptoInstruments.fulfilled, (state, action) => {
        return {
          ...state,
          allCryptoInstrument: action.payload
        }
      })
  }
})

export default cryptoInstrumentSlice.reducer
