import { createSlice } from '@reduxjs/toolkit'
import { fetchAdminsDetails, fetchRoleDetails, fetchAllAdmins, fetchAllRoles, fetchAllPermissions } from 'redux-thunk/thunk/admin'

const initialState = {
  allAdmins: null,
  adminDetail: null,
  allRoles: null,
  allPermissions: null,
  roleDetail: null
}

const adminSlice = createSlice({
  name: 'adminSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAdmins.fulfilled, (state, action) => {
        return {
          ...state,
          allAdmins: action.payload
        }
      })
      .addCase(fetchAdminsDetails.fulfilled, (state, action) => {
        return {
          ...state,
          adminDetail: action.payload
        }
      })
      .addCase(fetchAllRoles.fulfilled, (state, action) => {
        return {
          ...state,
          allRoles: action.payload
        }
      })
      .addCase(fetchAllPermissions.fulfilled, (state, action) => {
        return {
          ...state,
          allPermissions: action.payload
        }
      })
      .addCase(fetchRoleDetails.fulfilled, (state, action) => {
        return {
          ...state,
          roleDetail: action.payload
        }
      })
  }
})

export default adminSlice.reducer
