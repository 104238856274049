import React from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { Alert, Slide, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { handleToaster } from 'redux-thunk/redux/toasterSlice'
import { notificationRoot } from 'assets/dom/domNodes'

const TransitionLeft = (props) => {
  return <Slide {...props} direction='left' />
}

export const Toaster = ({ handleToasterClose }) => {
  const { openToaster, toasterMessage, toasterType } = useSelector(state => state.toaster)

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={2500}
      onClose={handleToasterClose}
      open={openToaster}
      style={{ maxWidth: '95vw', width: 'fit-content', padding: 0 }}
      TransitionComponent={TransitionLeft}
    >
      <Alert
        style={{
          fontSize: 16,
          opacity: 0.95
        }}
        id={`toaster_${toasterType}`}
        variant='filled'
        onClose={handleToasterClose}
        severity={toasterType}
      >
        {toasterMessage}
      </Alert>
    </Snackbar>
  )
}

export const AppToaster = () => {
  const { openToaster, toasterMessage, toasterType } = useSelector(state => state.toaster)
  const dispatch = useDispatch()
  const handleToasterClose = () => {
    dispatch(handleToaster({
      openToaster: false,
      toasterMessage,
      toasterType
    }))
  }

  return notificationRoot
    ? createPortal(<Toaster openToaster={openToaster} toasterMessage={toasterMessage} toasterType={toasterType} handleToasterClose={handleToasterClose} />, notificationRoot)
    : <></>
}

Toaster.defaultProps = {
  handleToasterClose: () => { }
}

Toaster.propTypes = {
  handleToasterClose: PropTypes.func
}

export default AppToaster
