import { createAsyncThunk } from '@reduxjs/toolkit'
import { createAssetService, deleteAssetService, getAllAssetsService } from 'network/services/assets.service'

/**
 * Get All Assets Thunk
 */
export const fetchAllAssets = createAsyncThunk(
  'fetch/allAssets',
  async (_, thunkApi) => {
    try {
      const res = await getAllAssetsService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Asset Thunk
 */
export const createAsset = createAsyncThunk(
  'create/asset',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createAssetService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Delete Asset Thunk
 */
export const deleteAsset = createAsyncThunk(
  'delete/asset',
  async ({ assetId, onSuccess }, thunkApi) => {
    try {
      const res = await deleteAssetService(assetId)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
