import { createSlice } from '@reduxjs/toolkit'
import { getChatRules, getUserChats, getChatLanguageRoom, getUserChatTipping, getChatGroups } from 'redux-thunk/thunk/chat'

const initialState = {
  chatRules: [],
  userChats: null,
  chatLanguageRoom: null,
  userTip: null,
  chatGroups: null
}

const chatSlice = createSlice({
  name: 'chat',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getChatRules.fulfilled, (state, action) => {
        return {
          ...state,
          chatRules: action.payload?.chatRules?.rules
        }
      })
      .addCase(getUserChats.fulfilled, (state, action) => {
        return {
          ...state,
          userChats: action.payload
        }
      })
      .addCase(getChatLanguageRoom.fulfilled, (state, action) => {
        return {
          ...state,
          chatLanguageRoom: action.payload
        }
      })
      .addCase(getUserChatTipping.fulfilled, (state, action) => {
        return {
          ...state,
          userTip: action.payload
        }
      })
      .addCase(getChatGroups.fulfilled, (state, action) => {
        return {
          ...state,
          chatGroups: action.payload
        }
      })
  }
})

export default chatSlice.reducer
