import { createAsyncThunk } from '@reduxjs/toolkit'
import { createChatLanguageRoomService, createChatRuleService, getChatLanguageRoomService, getChatRulesService, getChatGroupsService, getUserChatTippingService, getUserChatsService, updateChatLanguageRoomStatusService, updateChatGroupsStatusService, createChatGroupsService, editChatGroupsService } from 'network/services/chat.service'

export const getChatRules = createAsyncThunk(
  'chat/get-rules',
  async (thunkApi) => {
    try {
      const res = await getChatRulesService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getChatLanguageRoom = createAsyncThunk(
  'chat/get-language-room',
  async (data, thunkApi) => {
    try {
      const res = await getChatLanguageRoomService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateChatLanguageRoomStatus = createAsyncThunk(
  'update/chat-language-room',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateChatLanguageRoomStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createChatLanguageRoom = createAsyncThunk(
  'create/chat-language-room',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createChatLanguageRoomService(data)
      onSuccess(res.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createChatRules = createAsyncThunk(
  'chat/create-rules',
  async (data, thunkApi) => {
    try {
      const res = await createChatRuleService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getUserChats = createAsyncThunk(
  'user-chat/get',
  async (data, thunkApi) => {
    try {
      const res = await getUserChatsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getUserChatTipping = createAsyncThunk(
  'user-chat/tip',
  async (data, thunkApi) => {
    try {
      const res = await getUserChatTippingService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getChatGroups = createAsyncThunk(
  'chat-groups/get',
  async (data, thunkApi) => {
    try {
      const res = await getChatGroupsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateChatGroupsStatus = createAsyncThunk(
  'update/chat-groups',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateChatGroupsStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const editChatGroupsGroup = createAsyncThunk(
  'edit/chat-groups',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await editChatGroupsService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createChatGroups = createAsyncThunk(
  'create/chat-groups',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createChatGroupsService(data)
      onSuccess(res.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
