import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllCryptoInstrumentsService, updateCryptoInstrumentsService, updateCryptoInstrumentsStatusService } from 'network/services/crypto-instruments.service'

/**
 * Get All Crypto Instruments Thunk
 */
export const fetchAllCryptoInstruments = createAsyncThunk(
  'fetch/cryptoInstruments',
  async (data, thunkApi) => {
    try {
      const res = await getAllCryptoInstrumentsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Crypto Instruments Thunk
 */
export const updateCryptoInstruments = createAsyncThunk(
  'update/cryptoInstrument',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    console.log(data, 'data')
    try {
      const res = await updateCryptoInstrumentsService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Change Crypto Instruments Status Thunk
 */
export const updateCryptoInstrumentsStatus = createAsyncThunk(
  'update/cryptoInstrumentsStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateCryptoInstrumentsStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
