import { createSlice } from '@reduxjs/toolkit'
import { fetchAffiliateStats, fetchAllAffiliateTransactions, fetchAllAffiliatedPlayers, fetchAllAffiliates, fetchAllCommissionSettlement, fetchAllCommissions, fetchIndividualAffiliatePlayerStats } from 'redux-thunk/thunk/affiliates'

const initialState = {
  allAffiliates: null,
  affiliatePlayers: null,
  affiliateStats: null,
  allCommissions: null,
  allSettlementCommission: null,
  allAffiliateTransactions: null,
  individualAffiliateStats: null
}

const affiliateSlice = createSlice({
  name: 'affiliateSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAffiliates.fulfilled, (state, action) => {
        return {
          ...state,
          allAffiliates: action.payload
        }
      })
      .addCase(fetchAllCommissions.fulfilled, (state, action) => {
        return {
          ...state,
          allCommissions: action.payload
        }
      })
      .addCase(fetchAllAffiliatedPlayers.fulfilled, (state, action) => {
        return {
          ...state,
          affiliatePlayers: action.payload?.affiliateUsers || []
        }
      })
      .addCase(fetchAffiliateStats.fulfilled, (state, action) => {
        return {
          ...state,
          affiliateStats: action.payload
        }
      })
      .addCase(fetchAllCommissionSettlement.fulfilled, (state, action) => {
        return {
          ...state,
          allSettlementCommission: action.payload
        }
      })
      .addCase(fetchAllAffiliateTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          allAffiliateTransactions: action.payload
        }
      })
      .addCase(fetchIndividualAffiliatePlayerStats.fulfilled, (state, action) => {
        return {
          ...state,
          individualAffiliateStats: action.payload
        }
      })
  }
})

export default affiliateSlice.reducer
