import { createSlice } from '@reduxjs/toolkit'
import { fetchDashboardStatistics, fetchDashboardData, fetchFinancialReport, fetchPAndLReport } from 'redux-thunk/thunk/dashboard'

const initialState = {
  statistics: null,
  loading: false,
  players: {},
  financialReport: {},
  pAndLreport: {}
}

const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardStatistics.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchDashboardStatistics.fulfilled, (state, action) => {
        return {
          ...state,
          statistics: action.payload,
          loading: false
        }
      })
      .addCase(fetchDashboardStatistics.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(fetchDashboardData.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        return {
          ...state,
          players: action.payload,
          loading: false
        }
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(fetchFinancialReport.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchFinancialReport.fulfilled, (state, action) => {
        return {
          ...state,
          financialReport: action.payload,
          loading: false
        }
      })
      .addCase(fetchFinancialReport.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(fetchPAndLReport.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchPAndLReport.fulfilled, (state, action) => {
        return {
          ...state,
          pAndLreport: action.payload,
          loading: false
        }
      })
      .addCase(fetchPAndLReport.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
  }
})

export default dashboardSlice.reducer
