import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createSponsorService,
  getAllSponsorsService,
  getSponsorDetailService,
  updateSponsorDetailsService,
  updateSponsorStatusService
} from 'network/services/sponsors.service'

/**
 * Get All Sponsors Thunk
 */
export const fetchAllSponsors = createAsyncThunk(
  'fetch/allSponsors',
  async (data, thunkApi) => {
    try {
      const res = await getAllSponsorsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchSponsorsDetails = createAsyncThunk(
  'fetch/sponsorDetail',
  async (sponsorId, thunkApi) => {
    try {
      const res = await getSponsorDetailService(sponsorId)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Sponsor Thunk
 */
export const createSponsor = createAsyncThunk(
  'create/sponsor',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createSponsorService(data)
      onSuccess(res.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Sponsor Thunk
 */
export const updateSponsor = createAsyncThunk(
  'update/sponsorDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateSponsorDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Change Sponsor Status Thunk
 */
export const updateSponsorStatus = createAsyncThunk(
  'update/sponsorStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateSponsorStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
