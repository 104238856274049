import { ReactComponent as EnglishIcon } from 'assets/images/English.svg'
import { ReactComponent as FrenchIcon } from 'assets/images/French.svg'
import { ReactComponent as GermanIcon } from 'assets/images/German.svg'
import { ReactComponent as SpanishIcon } from 'assets/images/Spanish.svg'
import { ReactComponent as PortugueseIcon } from 'assets/images/Portuguese.svg'

/* ==========================================================================
  LANGUAGES
  ========================================================================== */
const LANGUAGES = [
  { label: 'English', languageCode: 'en', flag: EnglishIcon },
  { label: 'German', languageCode: 'de', flag: GermanIcon },
  { label: 'Spanish', languageCode: 'es', flag: SpanishIcon },
  { label: 'French', languageCode: 'fr', flag: FrenchIcon },
  { label: 'Portuguese', languageCode: 'pt', flag: PortugueseIcon }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
const METHOD_TYPES = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
}

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
const TOKEN = 'admin_authtoken'
const LANGUAGE = 'admin_language'
const ADMIN_ID = 'admin_id'
const ROLE = 'operator_role'

/* ==========================================================================
Operator Roles
========================================================================== */
const OPERATOR_ROLES = {
  operator: 'OPERATOR',
  operatorUser: 'OPERATOR_USER'
}

/* ==========================================================================
Loader types
========================================================================== */
const LOADER_TYPE = {
  SCALE: 'scale',
  PULSE: 'pulse'
}

/* ==========================================================================
  TOASTER / NOTIFICATION Types
========================================================================== */
const TOASTER_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
}

/* ==========================================================================
  Player Management Report Tabs
========================================================================== */

const PLAYER_REPORT_DATA_TAB = {
  ALL: 'all',
  CASINO: 'casino',
  CUSTOM: 'custom'
  // SPORTSBOOK: 'sportsBook',
  // CRYPTO: 'cryptoFutures',
  // ROLLER_COASTER: 'rollerCoaster'
}

/* ==========================================================================
  Loader Types
========================================================================== */
const LOADER_HANDLER_TYPES = {
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader'
}

/* ==========================================================================
  Table Cell Type
========================================================================== */
const TABLE_CELL_TYPE = {
  status: 'status',
  description: 'description',
  switch: 'switch',
  button: 'button',
  date: 'date',
  text: 'text',
  customText: 'customText',
  link: 'link',
  LINK_FOR_USER_DETAILS: 'LINK_FOR_USER_DETAILS',
  image: 'image',
  number: 'number',
  integer: 'integer',
  amount: 'amount',
  customAmount: 'customAmount',
  default: 'default',
  color: 'color',
  crashMultiplier: 'crashMultiplier',
  updateIconButton: 'updateIconButton',
  deleteIconButton: 'deleteIconButton',
  actionCell: 'actionCell',
  editAndDisableCell: 'editAndDisableCell',
  editDisableAndPinCell: 'editDisableAndPinCell',
  enableDisable: 'enableDisable',
  withdrawRequestAction: 'withdrawRequestAction',
  crmTemplateAction: 'crmTemplateAction',
  object: 'object',
  actionEnableDisableCell: 'actionEnableDisableCell',
  uploadIconButton: 'uploadIconButton',
  onlyEditAction: 'onlyEditAction',
  onlyViewAction: 'onlyViewAction',
  buttonWithCondition: 'buttonWithCondition',
  buttonForSettlement: 'buttonForSettlement',
  viewAndDisableEnableCell: 'viewAndDisableEnableCell',
  kycVerificationAction: 'kycVerificationAction'
}

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
const ROUTE_PATHS = {
  rootPath: '/',
  loginLog: '/login-logs',
  login: '/login',
  playerBalance: '/player-balance',
  onlinePlayer: '/online-player',
  changePassword: '/change-password',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:adminId/:token',
  settings: '/settings',
  dashboard: '/dashboard',
  profile: '/profile',
  gameSettings: '/game-settings',
  editGameSettings: '/game-settings/edit',
  users: '/users',
  usersDetail: '/users/:userId/details',
  createUser: '/users/create',
  editUser: '/users/:userId/edit',
  influencers: '/influencers',
  influencerDetail: '/influencers/:userId/details',
  createInfluencer: '/influencers/create',
  editInfluencer: '/influencers/:userId/edit',
  databaseExplorer: '/database-explorer',
  admins: '/system-manage/admins',
  roleManagement: '/system-manage/role-manage',
  ipManagement: '/system-manage/ip-manage',
  operationLog: '/system-manage/operation-manage',
  systemManagementLoginLogs: '/system-manage/login-logs',
  siteConfiguration: '/system-manage/site-config',
  createRole: '/roles/create',
  editRole: '/roles/:adminId/edit',
  roles: '/system-manage/roles',
  rolesDetail: '/system-manage/roles/:roleId/details',
  games: '/games-manage/games-custom',
  gamesSportsbook: '/games-manage/games-sportsbook',
  gamesProviders: '/games-manage/games-provider',
  editProvider: '/providers/:providerId/edit',
  gamesAggregators: '/games-manage/games-aggregator',
  gamesCategories: '/games-manage/games-categories',
  categoryDetail: '/categories/:categoryId/details',
  createCategory: '/categories/create',
  editCategory: '/categories/:categoryId/edit',
  editSiteConfig: '/siteConfiguration/:siteId/edit',
  sportsBettingTransactions: '/sportsBetting-transactions',
  casinoTransactions: '/casino-transactions',
  cryptoBetTransactions: '/cryptoBet-transactions',
  cryptoFutureReport: '/cryptoFuture-report',
  rollerCoasterReport: '/rollerCoaster-report',
  rollerCoasterTransactions: '/rollerCoaster-transactions',
  casinoProfitAndLossTransactions: '/transactions/casino-profitAndLoss-transactions',
  createAdmin: '/admins/create',
  adminsDetail: '/system-manage/admins/:adminId/details',
  editAdmin: '/admins/:adminId/edit',
  sponsors: '/sponsors',
  createSponsor: '/sponsors/create',
  editSponsor: '/sponsors/:sponsorId/edit',
  promotions: '/promotions',
  createPromotion: '/promotions/create',
  editPromotion: '/promotions/:promotionId/edit',
  viewPromotion: '/promotions/:promotionId/details',
  announcements: '/announcements',
  createAnnouncement: '/announcements/create',
  editAnnouncement: '/announcements/:announcementId/edit',
  reportFlag: '/report-flag',
  reportedUserDetail: '/report-flag/:reportedUserId/details',
  banners: '/banners',
  createBanner: '/banners/create',
  editBanner: '/banners/:bannerId/edit',
  gameThemeSettings: '/game-theme-settings',
  gameHeroTheme: '/game-themes/hero',
  createGameHeroTheme: '/game-themes/hero/create',
  editGameHeroTheme: '/game-themes/hero/:heroThemeId/edit',
  gameBackgroundTheme: '/game-themes/background',
  createGameBackgroundTheme: '/game-themes/background/create',
  editGameBackgroundTheme: '/game-themes/background/:backgroundThemeId/edit',
  transactions: '/transactions',
  affiliates: '/affiliates',
  settleAffiliateCommission: '/settle-affiliate-commission',
  createAffiliateCommission: '/affiliate-commission/create',
  editAffiliateSettings: '/affiliates/:affiliateId/edit',
  affiliatesPlayers: '/affiliates/:affiliateId/users',
  individualPlayersStats: '/individualPlayer/:id/details',
  affiliateTransactions: '/affiliate-transactions',
  assets: '/assets',
  cms: '/cms',
  createCms: '/cms/create',
  editCms: '/cms/:cmsId/edit',
  crmTemplate: '/crm-template',
  createCrmTemplate: '/crm-template/create',
  editCrmTemplate: '/crm-template/:crmTemplateId/edit',
  withdrawRequests: '/withdraw-requests',
  bonus: '/bonus',
  dailyBonus: '/bonus/daily',
  editDailyCashbackBonus: '/bonus/cashback/:bonusId/edit',
  editDailyRewardBonus: '/bonus/daily/:bonusId/edit',
  weeklyBonus: '/bonus/weekly',
  editWeeklyRewardBonus: '/bonus/weekly/:bonusId/edit',
  createDepositBonus: '/bonus/deposit/create',
  createOtherBonus: '/bonus/other/create',
  createFreeSpinBonus: '/bonus/free-spin/create',
  otherBonus: '/other-bonuses',
  depositBonus: '/bonus/deposit',
  freeSpinBonus: '/bonus/free-spin',
  depositBonusDetail: '/bonus/deposit/:bonusId/details',
  editDepositBonus: '/bonus/deposit/:bonusId/edit',
  editOtherBonus: '/bonus/other/:bonusId/edit',
  editFreeSpinBonus: '/bonus/free-spin/:bonusId/edit',
  otherBonusDetail: '/bonus/other/:bonusId/details',
  freeSpinBonusDetail: '/bonus/free-spin/:bonusId/details',
  editReferralBonus: '/bonus/:bonusId/edit-referral-bonus',
  editJoiningBonus: '/bonus/:bonusId/edit-joining-bonus',
  themeSettings: '/theme-settings',
  createThemeSetting: '/theme-setting/create',
  editThemeSetting: '/theme-settings/:themeSettingId/edit',
  upliftingWords: '/uplifting-words',
  editUpliftingWords: '/uplifting-words/edit',
  crashReports: '/reports/crash-reports',
  notFound: '/not-found',
  paymentTransactions: '/payment-transactions',
  chatRules: '/chat-rules',
  userChats: '/user-chats',
  userChatDetails: '/user-chat/:id/details',
  words: '/offensive-words',
  createWord: '/offensive-word/create',
  chatLanguageRoom: '/chat-language-room',
  chatGroups: '/chat-groups',
  createChatGroups: '/chat-groups/create',
  editChatGroups: '/chat-groups/:id/edit',
  tipsTransaction: '/user-tips-transaction',
  createChatLanguageRoom: '/chat-language-room/create',
  referrals: '/referrals',
  referralBonus: '/referral-bonus-settings',
  editReferralBonusSettings: '/referralBonus/:bonusId/edit',
  referralUsers: '/referrals/:referralId/users',
  individualReferralUsers: '/individual-referrals/:referralId/details',
  rankingLevels: '/ranking-levels',
  createRankingLevel: '/ranking-levels/create',
  editRankingLevel: '/ranking-levels/:rankId/edit',
  cryptoInstruments: '/crypto-instruments',
  editCryptoInstruments: '/crypto-instruments/:cryptoInstrumentId/edit',
  kycVerification: '/kyc-verification',
  kycVerificationDetails: '/kyc-verification/:id/details',
  cashback: '/cashback',
  orderGames: '/order-games'
}

/* ==========================================================================
  All Recent tabs that are available
========================================================================== */
export const ROUTES_NAME = {
  [ROUTE_PATHS.dashboard]: 'dashboard',
  [ROUTE_PATHS.loginLog]: 'loginLog',
  [ROUTE_PATHS.playerBalance]: 'playerBalance',
  [ROUTE_PATHS.users]: 'playerList',
  [ROUTE_PATHS.onlinePlayer]: 'onlinePlayer',
  [ROUTE_PATHS.createUser]: 'createNew',
  [ROUTE_PATHS.admins]: 'admins',
  [ROUTE_PATHS.roleManagement]: 'roleManagement',
  [ROUTE_PATHS.siteConfiguration]: 'siteConfiguration',
  [ROUTE_PATHS.ipManagement]: 'ipManangement',
  [ROUTE_PATHS.operationLog]: 'operationLog',
  [ROUTE_PATHS.systemManagementLoginLogs]: 'loginLogs',
  [ROUTE_PATHS.games]: 'customGames',
  [ROUTE_PATHS.gamesSportsbook]: 'games',
  [ROUTE_PATHS.gamesProviders]: 'providers',
  [ROUTE_PATHS.gamesAggregators]: 'aggregators',
  [ROUTE_PATHS.sportsBettingTransactions]: 'sportsBettingTransactions',
  [ROUTE_PATHS.casinoTransactions]: 'casinoTransactions',
  [ROUTE_PATHS.casinoProfitAndLossTransactions]: 'casinoProfitAndLossTransactions',
  [ROUTE_PATHS.gameSettings]: 'customGameSettings',
  [ROUTE_PATHS.transactions]: 'customGamesTransactions',
  [ROUTE_PATHS.cms]: 'cms',
  [ROUTE_PATHS.crmTemplate]: 'crmTemplate',
  [ROUTE_PATHS.affiliates]: 'affiliates',
  [ROUTE_PATHS.affiliateTransactions]: 'affiliateTransactions',
  [ROUTE_PATHS.settleAffiliateCommission]: 'commissionSettlement',
  [ROUTE_PATHS.sponsors]: 'sponsors',
  [ROUTE_PATHS.reportFlag]: 'reportAndFlag',
  [ROUTE_PATHS.promotions]: 'promotions',
  [ROUTE_PATHS.bonus]: 'bonusSetting',
  [ROUTE_PATHS.withdrawRequests]: 'withdrawRequests',
  [ROUTE_PATHS.paymentTransactions]: 'paymentTransactions',
  [ROUTE_PATHS.assets]: 'imageGallery',
  [ROUTE_PATHS.depositBonus]: 'depositBonus',
  [ROUTE_PATHS.registrationBonus]: 'registrationBonus',
  [ROUTE_PATHS.freeSpinBonus]: 'freeSpinBonus',
  [ROUTE_PATHS.gamesCategories]: 'gameCategories',
  [ROUTE_PATHS.databaseExplorer]: 'databaseExplorer',
  [ROUTE_PATHS.chatRules]: 'chatRules',
  [ROUTE_PATHS.userChats]: 'userChats',
  [ROUTE_PATHS.announcements]: 'announcements',
  [ROUTE_PATHS.words]: 'offensiveWords',
  [ROUTE_PATHS.chatLanguageRoom]: 'languagesRoom',
  [ROUTE_PATHS.chatGroups]: 'chatGroups',
  [ROUTE_PATHS.tipsTransaction]: 'tipsTransaction',
  [ROUTE_PATHS.referrals]: 'referrals',
  [ROUTE_PATHS.referralBonus]: 'referralBonus'
}

/* ==========================================================================
  DIALOG Types
========================================================================== */
const ACTION_TYPE = {
  add: 'add',
  update: 'update',
  delete: 'delete',
  view: 'view',
  hide: '',
  pin: 'pin',
  reRequest: 're-requested',
  approve: 'approve',
  reject: 'reject'
}

const DEFAULT_GAME_ID = {
  CRASH: 1
}

const USER_TYPES = {
  BOT: 'BOT',
  USER: 'USER',
  ADMIN: 'ADMIN',
  INFLUENCER: 'INFLUENCER'
}

const USER_FILTER_FOR_MAIL = {
  ALL_USERS: 'ALL_USERS',
  ALL_ACTIVE_USERS: 'ALL_ACTIVE_USERS',
  LAST_ACTIVE_USERS_FROM_PAST_ONE_WEEK: 'LAST_ACTIVE_USERS_FROM_PAST_ONE_WEEK',
  LAST_ACTIVE_USERS_FROM_PAST_ONE_MONTH: 'LAST_ACTIVE_USERS_FROM_PAST_ONE_MONTH',
  LAST_ACTIVE_USERS_FROM_PAST_THREE_MONTHS: 'LAST_ACTIVE_USERS_FROM_PAST_THREE_MONTHS',
  LAST_ACTIVE_USERS_FROM_PAST_SIX_MONTHS: 'LAST_ACTIVE_USERS_FROM_PAST_SIX_MONTHS',
  LAST_ACTIVE_USERS_FROM_PAST_ONE_YEAR: 'LAST_ACTIVE_USERS_FROM_PAST_ONE_YEAR',
  ALL_INACTIVE_USERS: 'ALL_INACTIVE_USERS',
  LAST_INACTIVE_USERS_FROM_PAST_ONE_WEEK: 'LAST_INACTIVE_USERS_FROM_PAST_ONE_WEEK',
  LAST_INACTIVE_USERS_FROM_PAST_ONE_MONTH: 'LAST_INACTIVE_USERS_FROM_PAST_ONE_MONTH',
  LAST_INACTIVE_USERS_FROM_PAST_THREE_MONTHS: 'LAST_INACTIVE_USERS_FROM_PAST_THREE_MONTHS',
  LAST_INACTIVE_USERS_FROM_PAST_SIX_MONTHS: 'LAST_INACTIVE_USERS_FROM_PAST_SIX_MONTHS',
  LAST_INACTIVE_USERS_FROM_PAST_ONE_YEAR: 'LAST_INACTIVE_USERS_FROM_PAST_ONE_YEAR'
}

const AUTOMATIC_MAILS = [
  'WELCOME_ONBOARD',
  'WELCOME_AND_VERIFY_EMAIL',
  'RESET_PASSWORD'
]

const FONT_LIST = [
  'ui-monospace',
  'sans-serif',
  'system-ui',
  'serif',
  'monospace'
]

export const PERMISSION_LABEL = {
  R: 'Read',
  C: 'Create',
  T: 'Toggle',
  U: 'Update',
  D: 'Delete'
}

export const PERMISSIONS = {
  READ: 'R',
  CREATE: 'C',
  TOGGLE: 'T',
  UPDATE: 'U',
  DELETE: 'D'
}

export const DISABLE_ACTION_CELL = {
  check: 'check',
  eye: 'eye',
  edit: 'edit'
}

export const ROLE_NAMES = {
  superAdministrator: 'Super Administrator',
  generalManager: 'General Manager',
  platformAdministrator: 'Platform Administrator'
}

export const THEME_MODE = {
  DARK: 'dark',
  LIGHT: 'light'
}

export const AFFILIATE_SETTLEMENT_STATUS = {
  COMPLETED: 'completed',
  PENDING: 'pending'
}

export const BONUS_STATUS = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  REFERRAL: 'referral',
  CASHBACk: 'cashback',
  OTHER_BONUS: 'other'
}

export const SYSTEM_KYC_STATUS = {
  PENDING: 'pending',
  ON_HOLD: 'onHold',
  COMPLETED: 'completed',
  REJECTED: 'rejected',
  RE_REQUESTED: 're-requested'
}

// Export All constant variables
export {
  TOASTER_TYPE,
  LANGUAGES,
  TOKEN,
  LANGUAGE,
  ADMIN_ID,
  ROLE,
  OPERATOR_ROLES,
  ROUTE_PATHS,
  LOADER_TYPE,
  METHOD_TYPES,
  LOADER_HANDLER_TYPES,
  TABLE_CELL_TYPE,
  DEFAULT_GAME_ID,
  ACTION_TYPE,
  USER_TYPES,
  USER_FILTER_FOR_MAIL,
  FONT_LIST,
  AUTOMATIC_MAILS,
  PLAYER_REPORT_DATA_TAB
}
