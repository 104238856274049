import { createSlice } from '@reduxjs/toolkit'
import { fetchAllKycVerificationRequests, fetchUserKycVerificationDetails } from 'redux-thunk/thunk/kycVerification'

const initialState = {
  allKycVerificationRequests: null,
  userKycVerificationDetailsData: null
}

const kycVerificationSlice = createSlice({
  name: 'kycVerificationSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllKycVerificationRequests?.fulfilled, (state, action) => {
        return {
          ...state,
          allKycVerificationRequests: action.payload
        }
      })
      .addCase(fetchUserKycVerificationDetails?.fulfilled, (state, action) => {
        return {
          ...state,
          userKycVerificationDetailsData: action.payload
        }
      })
  }
})

export default kycVerificationSlice.reducer
