import { createSlice } from '@reduxjs/toolkit'
import { getAdminLoginLogs } from 'redux-thunk/thunk/systemManagement.js/loginLog'

const initialState = {
  loading: false
}

const adminLoginLogSlice = createSlice({
  name: 'adminLoginLog',
  initialState: initialState,
  extraReducers: builder => {
    builder
      .addCase(getAdminLoginLogs.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(getAdminLoginLogs.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          loginLogs: action.payload
        }
      })
      .addCase(getAdminLoginLogs.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
  }
})

export default adminLoginLogSlice?.reducer
