import { createAsyncThunk } from '@reduxjs/toolkit'
import { approvedKycVerificationRequestService, getAllKycVerificationRequestService, getUserKycVerificationDetailsService } from 'network/services/kyc-verification.service'

export const fetchAllKycVerificationRequests = createAsyncThunk(
  'fetch/allKycVerificationRequests',
  async (data, thunkApi) => {
    try {
      const res = await getAllKycVerificationRequestService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const approvedKycVerificationRequest = createAsyncThunk(
  'approved/kycVerificationRequest',
  async ({ data, onSuccess, handleRejectDialogClose, handleReRequestedDialogClose }, thunkApi) => {
    try {
      const res = await approvedKycVerificationRequestService(data)
      onSuccess()
      handleRejectDialogClose()
      handleReRequestedDialogClose()
      return res
    } catch (error) {
      handleReRequestedDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchUserKycVerificationDetails = createAsyncThunk(
  'fetch/userKycVerificationDetails',
  async (data, thunkApi) => {
    try {
      const res = await getUserKycVerificationDetailsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
