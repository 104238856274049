import { styled, TextField } from '@mui/material'

export const StyledTextField = styled(TextField)(({ theme, error, labeltype }) => ({
  '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  '& input[type=number]': {
    MozAppearance: 'textfield'
  },
  '& .MuiInputBase-root': {
    backgroundColor: labeltype !== 'filter' && labeltype !== 'dateFilter'
      ? theme.colors.white
      : 'transparent',
    paddingRight: 0,
    '& .MuiInputAdornment-root': {
      // position: 'absolute',
      // right: '14px',
      // backgroundColor: 'transparent'
      marginRight: '14px'
    }
  },
  '& .MuiInputBase-input': {
    padding: labeltype !== 'filter' && labeltype !== 'dateFilter' ? 12 : 6
  },
  '& .MuiOutlinedInput-root': {
    ...(labeltype !== 'default' &&
      {
        '& fieldset': {
          border: 'none'
        }
      }
    ),

    '&:hover fieldset': {
      borderColor: error ? theme.palette.primary.error : theme.palette.primary.main,
      borderWidth: '1px'
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? theme.palette.primary.error : theme.palette.primary.main,
      borderWidth: '1px'
    }
  },
  ...(labeltype === 'dateFilter' ? { width: '100%' } : {})
}))
