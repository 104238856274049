import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

export const getCrashGameReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/crash-games-report', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllGameSettingsService = () => {
  return axiosInstance(METHOD_TYPES.get, '/games/all-game-settings', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const updateGameSettingsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/games/update-game-setting', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.gameSettingUpdated
  })
}

export const getAllGamesService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/games/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllSportsbookGamesService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/nux-game/games', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllSmartSoftCasinoGamesService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/list-games', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getGamesProviderService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/providers', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getGamesAggregatorsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/aggregators', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getSportsbettingTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/sportsbook/transactions', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getCasinoTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/casino-transactions', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getCryptoBetTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/get-crypto-bets', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getRollerCoasterTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/get-rollercoaster-bets', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const updateGameStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/casino/update-status', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.gameStatusUpdatedSuccessfully
  })
}

export const updateGameProviderDataService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/casino/update-provider', data, {
    server: microServices.USER_URL,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.providersDataUpdateSuccessfully
  })
}

export const createGameAssetService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/casino/upload-image', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.imageUploadedSuccessfully
  })
}

export const getCasinoCatagoriesService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/categories', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const updateCasinoCategoryStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/casino/categories-update-status', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.statusUpdatedSuccessfully
  })
}

export const createGameCategoryAssetService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/casino/upload-category-image', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.imageUploadedSuccessfully
  })
}

export const createGameCategoryService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/casino/create-category', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.categoryCreatedSuccessfully
  })
}

export const updateGameCategoryService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/casino/update-category', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.categoryUpdatedSuccessfully
  })
}

export const updateGameProviderStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/casino/update-provider-status', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.providerStatusUpdatedSuccessfully
  })
}

export const updateCategorySortOrderService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/casino/update-sort-order', { gameCategoryIds: data }, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.orderChangedSuccessfully
  })
}

export const updateProviderSortOrderService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/casino/update-provider-sort-order', { providersIds: data }, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.orderChangedSuccessfully
  })
}

export const updateGamesSortOrderService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/casino/update-casino-games-sort-order', { gameIds: data }, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.orderChangedSuccessfully
  })
}

export const updateGamesCategoryService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/casino/update-casino-games-category', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.categoryUpdatedSuccessfully
  })
}
