import { createSlice } from '@reduxjs/toolkit'
import { fetchAllAssets } from 'redux-thunk/thunk/galleryAssets'

const initialState = {
  allAssets: null
}

const assetsSlice = createSlice({
  name: 'assetsSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAssets.fulfilled, (state, action) => {
        return {
          ...state,
          allAssets: action.payload.assets
        }
      })
  }
})

export default assetsSlice.reducer
