import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  checkMailPreviewService,
  createCrmService,
  getAllCrmService,
  getCrmDetailService,
  scheduleMailService,
  updateCrmDetailsService
} from 'network/services/crm.service'

/**
 * Get All Crm Thunk
 */
export const fetchAllCrm = createAsyncThunk(
  'fetch/allCrm',
  async (data, thunkApi) => {
    try {
      const res = await getAllCrmService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchCrmDetails = createAsyncThunk(
  'fetch/crmDetail',
  async (crmId, thunkApi) => {
    try {
      const res = await getCrmDetailService(crmId)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Crm Thunk
 */
export const createCrm = createAsyncThunk(
  'create/crm',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createCrmService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Crm Thunk
 */
export const updateCrm = createAsyncThunk(
  'update/crmDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateCrmDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Send email Crm Thunk
 */
export const sendMailForPreview = createAsyncThunk(
  'test/crmPreview',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await checkMailPreviewService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Schedule emails Crm Thunk
 */
export const scheduleMailToUsers = createAsyncThunk(
  'schedule/mails',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await scheduleMailService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
