import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createAnnouncementService,
  getAllAnnouncementsService,
  updateAnnouncementDetailsService
  // updatePromotionStatusService
} from 'network/services/announcement.service'

/**
 * Get All Announcements Thunk
 */
export const fetchAllAnnouncements = createAsyncThunk(
  'fetch/allAnnouncements',
  async (data, thunkApi) => {
    try {
      const res = await getAllAnnouncementsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Announcement Thunk
 */
export const createAnnouncement = createAsyncThunk(
  'create/announcement',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createAnnouncementService(data)
      onSuccess(res.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Announcement Thunk
 */
export const updateAnnouncement = createAsyncThunk(
  'update/announcementDetail',
  async ({ data, onSuccess, handleDialogClose, handlePinnedDialogClose }, thunkApi) => {
    try {
      const res = await updateAnnouncementDetailsService(data)
      onSuccess()
      handleDialogClose()
      handlePinnedDialogClose()
      return res
    } catch (error) {
      // handleDialogClose() // Optionally close the first dialog on error
      handlePinnedDialogClose() // Close the second dialog on error
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
